/**
 * @generated SignedSource<<e8b8bffcce71b2d17e13cb585d28a5d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedNewArrivals_user$data = {
  readonly serviceId: string | null;
  readonly " $fragmentType": "HpSharedNewArrivals_user";
};
export type HpSharedNewArrivals_user$key = {
  readonly " $data"?: HpSharedNewArrivals_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedNewArrivals_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedNewArrivals_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "b75260ef06bf468f1f0be765e7269fb6";

export default node;
