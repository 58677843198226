/**
 * @generated SignedSource<<d542691e75f88d04b4186e63cf0ef5d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useRecentHistoryList_items$data = ReadonlyArray<{
  readonly browseUrl: string | null;
  readonly categoryCode: string | null;
  readonly categoryPath: string | null;
  readonly contemporaryTrackingString: string;
  readonly ecommerceTrackingParams: any | null;
  readonly firstPhotoWebPath: string | null;
  readonly pdpURL: string | null;
  readonly seller: {
    readonly serviceId: string | null;
  } | null;
  readonly serviceId: string | null;
  readonly title: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"AuctionPricePaddle_item">;
  readonly " $fragmentType": "useRecentHistoryList_items";
}>;
export type useRecentHistoryList_items$key = ReadonlyArray<{
  readonly " $data"?: useRecentHistoryList_items$data;
  readonly " $fragmentSpreads": FragmentRefs<"useRecentHistoryList_items">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "useRecentHistoryList_items",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AuctionPricePaddle_item"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ecommerceTrackingParams",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contemporaryTrackingString",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "size",
          "value": "small"
        }
      ],
      "kind": "ScalarField",
      "name": "firstPhotoWebPath",
      "storageKey": "firstPhotoWebPath(size:\"small\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pdpURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "browseUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "categoryCode",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "categoryPath",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Seller",
      "kind": "LinkedField",
      "name": "seller",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};
})();

(node as any).hash = "f37ce0661d75ee405b854a2a6acfe215";

export default node;
