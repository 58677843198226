/**
 * @generated SignedSource<<b009dc2a440ae2e9129581540cebfb75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecommendedItem_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"RecommendedItemHeart_viewer">;
  readonly " $fragmentType": "RecommendedItem_viewer";
};
export type RecommendedItem_viewer$key = {
  readonly " $data"?: RecommendedItem_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecommendedItem_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "loadPortfolioData"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "userIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecommendedItem_viewer",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "loadPortfolioData",
          "variableName": "loadPortfolioData"
        },
        {
          "kind": "Variable",
          "name": "userIds",
          "variableName": "userIds"
        }
      ],
      "kind": "FragmentSpread",
      "name": "RecommendedItemHeart_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "576f6d1083bf3b481256d4d529d492a0";

export default node;
