import { useId, useState, type FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { Carousel } from 'dibs-carousel';
import classnames from 'classnames';
import { InViewport } from 'dibs-in-viewport/exports/InViewport';
import { SharedCarouselDot } from '../../sharedComponents/SharedCarouselDot/SharedCarouselDot';
import { HpSharedCarouselTracking } from '../HpSharedCarouselTracking/HpSharedCarouselTracking';
import { HpSharedModuleContainer } from '../HpSharedModuleContainer/HpSharedModuleContainer';
import {
    trackPromoClick,
    trackPromoImpressions,
    buildPromoTrackingObject,
} from '../helpers/promoTrackingHelpers';
import { trackModuleLocation, trackModule } from '../helpers/moduleLocationTracking';
import { HpSharedTopHeroBannerItem } from '../HpSharedSharedHeroBanner/HpSharedSharedHeroBanner';
import { HpSharedTopHeroBannerImageItem } from './HpSharedTopHeroBannerImageItem';

import { type HpSharedTopHeroBannerModule_componentModule$data as ComponentModule } from './__generated__/HpSharedTopHeroBannerModule_componentModule.graphql';

import styles from './HpSharedTopHeroBannerModule.scss';

type Props = {
    componentModule: ComponentModule;
    useLazyLoadImages?: boolean;
    pageSetOffset?: boolean;
    isMobile?: boolean;
    totalModules: number;
    moduleIndex: number;
};

export const HpSharedTopHeroBannerModuleComponent: FC<Props> = ({
    componentModule,
    useLazyLoadImages,
    totalModules,
    moduleIndex,
}) => {
    const htmlId = `topHeroBanner-${useId()}`;
    const [videoPlaying, setVideoPlaying] = useState(false);

    if (!componentModule) {
        return null;
    }

    const items = componentModule.messages?.items || [];
    const isFullBleed = !!items.find(item => item?.isFullBleedImage);
    const isFullWidth = !!items.find(item => item?.format === 'CAPTION');
    const itemsPerPage = 1;
    const autoRunDuration = parseInt(componentModule.carouselSpeed || '', 10) || 5000;

    const onItemsImpression = ({
        visibleItems,
        index,
    }: {
        visibleItems: typeof items;
        index: number;
    }): void => {
        const promoTrackingObjects = visibleItems.map(item => {
            return buildPromoTrackingObject({
                id: 'banner-carousel',
                name: 'Banner Carousel',
                item,
                index,
            });
        });
        trackPromoImpressions(promoTrackingObjects);
    };

    const handleClick = (item: (typeof items)[0], index: number): void => {
        const promoTrackingObject = buildPromoTrackingObject({
            id: 'banner-carousel',
            name: 'Banner Carousel',
            item,
            index,
        });
        trackPromoClick(promoTrackingObject);
        trackModuleLocation({
            label: 'banner carousel module',
            moduleIndex,
            totalModules,
        });
        trackModule(componentModule.cmsDisplayTitle);
    };

    return (
        <div
            className={classnames(styles.moduleContainer, {
                [styles.fullWidth]: isFullWidth,
                [styles.isFullBleed]: isFullBleed,
            })}
        >
            <HpSharedModuleContainer>
                <HpSharedCarouselTracking
                    items={items}
                    itemsPerPage={itemsPerPage}
                    onItemsImpression={onItemsImpression}
                >
                    {({ handleIndexChange }) => (
                        <InViewport>
                            {({ inViewport }) => (
                                <Carousel
                                    classNames={{
                                        wrapper: styles.carouselWrapper,
                                        list: isFullBleed
                                            ? styles.fullBleedListWrapper
                                            : styles.listWrapper,
                                        item: styles.carouselItem,
                                    }}
                                    autoRunDuration={autoRunDuration}
                                    isAutoRun={
                                        (inViewport && items.length > 1 && !videoPlaying) || false
                                    }
                                    dataTn="hero-items-carousel"
                                    showDots={items.length > 1}
                                    hideArrows
                                    totalItems={items.length}
                                    itemsPerPage={itemsPerPage}
                                    step={itemsPerPage}
                                    renderItem={({
                                        index,
                                        isVisible,
                                    }: {
                                        isVisible: boolean;
                                        index: number;
                                    }) => {
                                        const item =
                                            componentModule?.messages?.items?.[index] || null;
                                        if (!item) return null;
                                        const { format, videoAutoplay } = item;

                                        return format === 'CAPTION' ? (
                                            <HpSharedTopHeroBannerItem
                                                onClick={() => handleClick(items[index], index)}
                                                isVisible={isVisible}
                                                useLazyLoadImages={useLazyLoadImages}
                                                topItem={item}
                                                videoPlaying={videoPlaying}
                                                onVideoStateChange={() => {
                                                    if (!videoAutoplay) {
                                                        setVideoPlaying(playing => !playing);
                                                    }
                                                }}
                                            />
                                        ) : (
                                            <HpSharedTopHeroBannerImageItem
                                                handleClick={() => handleClick(items[index], index)}
                                                isVisible={isVisible}
                                                index={index}
                                                useLazyLoadImages={useLazyLoadImages}
                                                bannerItem={item}
                                                itemId={`${htmlId}-${index}`}
                                            />
                                        );
                                    }}
                                    onIndexChange={(args: { prev: number; index: number }) => {
                                        handleIndexChange(args);
                                        setVideoPlaying(false);
                                    }}
                                    renderDot={({
                                        isCurrentDot,
                                    }: { isCurrentDot?: boolean } = {}) => (
                                        <SharedCarouselDot isActive={isCurrentDot} />
                                    )}
                                />
                            )}
                        </InViewport>
                    )}
                </HpSharedCarouselTracking>
            </HpSharedModuleContainer>
        </div>
    );
};

export const HpSharedTopHeroBannerModule = createFragmentContainer(
    HpSharedTopHeroBannerModuleComponent,
    {
        componentModule: graphql`
            fragment HpSharedTopHeroBannerModule_componentModule on TopHeroBannerModule {
                cmsDisplayTitle
                carouselSpeed
                messages(userType: $userType) {
                    items {
                        format
                        videoAutoplay
                        cmsId
                        isFullBleedImage
                        imageUrl
                        linkData {
                            path
                        }
                        ...HpSharedTopHeroBannerImageItem_bannerItem
                        ...HpSharedSharedHeroBanner_topItem
                    }
                }
            }
        `,
    }
);
