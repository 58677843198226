/**
 * @generated SignedSource<<7cf98317d2caa81f866c925421699be7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedHeroBannerText_linkData$data = {
  readonly path: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"SeoButton_linkData">;
  readonly " $fragmentType": "HpSharedHeroBannerText_linkData";
};
export type HpSharedHeroBannerText_linkData$key = {
  readonly " $data"?: HpSharedHeroBannerText_linkData$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedHeroBannerText_linkData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedHeroBannerText_linkData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SeoButton_linkData"
    }
  ],
  "type": "LinkData",
  "abstractKey": null
};

(node as any).hash = "f59be22cc6c2e1482d2fb977bd509283";

export default node;
