/**
 * @generated SignedSource<<df84e52ec82dc98fd25453238faf6f96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecommendedItemsCarousel_viewer$data = {
  readonly recommendedItems: ReadonlyArray<{
    readonly serviceId: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"RecommendedItem_item">;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"RecommendedItem_viewer">;
  readonly " $fragmentType": "RecommendedItemsCarousel_viewer";
};
export type RecommendedItemsCarousel_viewer$key = {
  readonly " $data"?: RecommendedItemsCarousel_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecommendedItemsCarousel_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "userIds",
  "variableName": "userIds"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "loadPortfolioData"
    },
    {
      "defaultValue": 18,
      "kind": "LocalArgument",
      "name": "pageSize"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "recentlyViewedIds"
    },
    {
      "defaultValue": 15,
      "kind": "LocalArgument",
      "name": "returnAmount"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "userIds"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": require('./RecommendedItemsCarouselViewerRefetchQuery.graphql')
    }
  },
  "name": "RecommendedItemsCarousel_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "pageSize",
          "variableName": "pageSize"
        },
        {
          "kind": "Variable",
          "name": "recentlyViewedIds",
          "variableName": "recentlyViewedIds"
        },
        {
          "kind": "Variable",
          "name": "returnAmount",
          "variableName": "returnAmount"
        },
        (v0/*: any*/)
      ],
      "concreteType": "Item",
      "kind": "LinkedField",
      "name": "recommendedItems",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "serviceId",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RecommendedItem_item"
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "loadPortfolioData",
          "variableName": "loadPortfolioData"
        },
        (v0/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "RecommendedItem_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "03d12ee7a5fb47be50563e6750039690";

export default node;
