import { type FC, type ComponentProps, useCallback } from 'react';
import { useFragment, graphql, usePaginationFragment } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';

import { HpSharedItemTilesCarouselModule } from '../HpSharedItemTilesCarouselModule/HpSharedItemTilesCarouselModule';

import {
    trackEvent,
    eventNameConstants,
    interactionTypeConstants,
    stepInteractionConstants,
    triggerConstants,
} from 'dibs-tracking';

import { flattenConnectionNodes } from 'dibs-ts-utils/exports/flattenConnectionNodes';
import { type HpSharedMostSavedItemsCarousel_viewer$key } from './__generated__/HpSharedMostSavedItemsCarousel_viewer.graphql';
import { type HpSharedMostSavedItemsCarousel_user$key } from './__generated__/HpSharedMostSavedItemsCarousel_user.graphql';

const viewerFragment = graphql`
    fragment HpSharedMostSavedItemsCarousel_viewer on Viewer
    @refetchable(queryName: "HpSharedMostSavedItemsCarouselPaginationQuery")
    @argumentDefinitions(count: { type: "Int", defaultValue: 6 }, cursor: { type: "String" }) {
        mostSavedItems: itemSearchItems(
            first: $count
            after: $cursor
            uriRef: "/collections/most-saved-items/?sort=trending"
            censored: true
            userCountryCode: $userCountryCode
            priceBookName: $priceBookName
        ) @connection(key: "HpSharedMostSavedItemsCarousel_mostSavedItems", filters: []) {
            edges {
                node {
                    ...HpSharedItemTilesCarouselModule_items
                        @arguments(
                            fetchFavorites: $hasUserId
                            userId: $userId
                            isTrade: $isTrade
                            priceBookName: $priceBookName
                        )
                }
            }
            totalResults
        }
        ...HpSharedItemTilesCarouselModule_viewer
            @arguments(fetchFavorites: $hasUserId, userId: $userId)
    }
`;

const userFragment = graphql`
    fragment HpSharedMostSavedItemsCarousel_user on User {
        ...HpSharedItemTilesCarouselModule_user
    }
`;

export const HpSharedMostSavedItemsCarousel: FC<{
    viewer?: HpSharedMostSavedItemsCarousel_viewer$key | null;
    user?: HpSharedMostSavedItemsCarousel_user$key | null;
    isMobile: boolean;
}> = ({ viewer: viewerRef, user: userRef, isMobile }) => {
    const { data: viewer, loadNext } = usePaginationFragment(viewerFragment, viewerRef);
    const user = useFragment(userFragment, userRef);

    const onArrowClick: ComponentProps<typeof HpSharedItemTilesCarouselModule>['onArrowClick'] =
        useCallback(() => {
            trackEvent({
                eventName: eventNameConstants.EVENT_PRODUCT_INTERACTION,
                interaction_type: interactionTypeConstants.INTERACTION_TYPE_HOMEPAGE,
                step_interaction_name:
                    stepInteractionConstants.STEP_INTERACTION_MOST_SAVED_ARROW_CLICK,
                trigger: triggerConstants.TRIGGER_MOST_SAVED,
            });
        }, []);

    const items = flattenConnectionNodes(viewer?.mostSavedItems);
    const totalResults = viewer?.mostSavedItems?.totalResults || 0;

    return (
        <HpSharedItemTilesCarouselModule
            viewer={viewer}
            user={user}
            items={items}
            isMobile={isMobile}
            totalResults={totalResults}
            trackingList={triggerConstants.TRIGGER_MOST_SAVED}
            loadNext={loadNext}
            onArrowClick={onArrowClick}
            title={
                <FormattedMessage
                    id="HpSharedMostSavedItemsCarousel.title"
                    defaultMessage="Most-Saved Items"
                />
            }
            viewMoreLink="/collections/most-saved-items/?sort=newest"
            dataTn="most-saved-items"
        />
    );
};
