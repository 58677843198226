import { type FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import classnames from 'classnames';

import dibsCss from 'dibs-css';
import { useCurrency } from 'dibs-buyer-layout/exports/useCurrency';
import { Link } from 'dibs-elements/exports/Link';
import ArrowRight from 'dibs-icons/exports/legacy/ArrowRight';
import { ItemTile } from 'dibs-search-product-tile/exports/ItemTile';

import { SharedHeart } from '../../sharedComponents/SharedHeart';
import { useHomePageContext } from '../HomePageContext';
import {
    trackCategoryClick,
    trackItemImpression,
    trackItemClick,
} from '../helpers/newArrivalsTracking';

import styles from './HpSharedNewArrivalsModule.scss';

import { type HpSharedNewArrivalsModule_viewer$key } from './__generated__/HpSharedNewArrivalsModule_viewer.graphql';
import { type HpSharedNewArrivalsModule_personalizationModule$key } from './__generated__/HpSharedNewArrivalsModule_personalizationModule.graphql';

const viewerFragment = graphql`
    fragment HpSharedNewArrivalsModule_viewer on Viewer {
        ...ItemTile_viewer
    }
`;

const personalizationModuleFragment = graphql`
    fragment HpSharedNewArrivalsModule_personalizationModule on PersonalizationModule {
        linkData {
            path
        }
        categoryTitle
        categoryItems {
            ...newArrivalsTracking_item
            ...SharedHeart_item
            ...ItemTile_item
                @arguments(
                    isTrade: $isTrade
                    priceBookName: $priceBookName
                    fetchLiveShipmentQuote: false
                )
        }
        ...newArrivalsTracking_personalizationModule
    }
`;

type Props = {
    viewer: HpSharedNewArrivalsModule_viewer$key;
    personalizationModule: HpSharedNewArrivalsModule_personalizationModule$key | null;
    moduleIndex: number;
    favorites: $TSFixMe;
};

const HpSharedNewArrivalsModule: FC<Props> = ({
    viewer: viewerRef,
    personalizationModule: personalizationModuleRef,
    moduleIndex,
    favorites,
}) => {
    const { currency } = useCurrency();
    const { isMobile } = useHomePageContext();

    const viewer = useFragment(viewerFragment, viewerRef);
    const personalizationModule = useFragment(
        personalizationModuleFragment,
        personalizationModuleRef
    );

    const { linkData, categoryTitle, categoryItems } = personalizationModule || {};
    const moduleItems = categoryItems || [];
    const itemsPerModule = moduleItems.length;

    return (
        <div
            className={classnames(
                dibsCss.flex,
                dibsCss.flexCol,
                dibsCss.bgWhite,
                dibsCss.maxWFull,
                {
                    [dibsCss.pSmall]: !isMobile,
                    [dibsCss.pXsmall]: isMobile,
                }
            )}
        >
            <div
                className={classnames(
                    dibsCss.sassyFontHeaderMedium,
                    dibsCss.mXsmall,
                    dibsCss.mbMedium
                )}
            >
                <Link
                    href={linkData?.path || ''}
                    underline="none"
                    onClick={() => {
                        trackCategoryClick({
                            personalizationModule: personalizationModule || null,
                        });
                    }}
                >
                    {categoryTitle}
                    <ArrowRight
                        className={classnames(dibsCss.h14px, dibsCss.w14px, dibsCss.mlSmaller)}
                    />
                </Link>
            </div>
            <div
                className={classnames(dibsCss.flex, dibsCss.mxXsmall, {
                    [dibsCss.gapSmall]: !isMobile,
                    [dibsCss.gapSmaller]: isMobile,
                })}
            >
                {moduleItems.map((item, index) => {
                    const itemIndex = moduleIndex * itemsPerModule + index;

                    return (
                        <div key={index} className={styles.itemTileWrapper}>
                            <ItemTile
                                useLoFiLazyLoader
                                disableBorder
                                currency={currency}
                                disableContentMargin
                                disableContentPadding
                                imageSize="imageSmall"
                                index={index}
                                item={item}
                                itemSearch={null}
                                showMeasurements={false}
                                showMSKUSwatch={false}
                                showQuickView={false}
                                viewer={viewer}
                                favoritesComponent={
                                    <SharedHeart
                                        item={item}
                                        trackingList="homepage - new arrivals"
                                        favorites={favorites}
                                    />
                                }
                                onContentVisible={() => {
                                    trackItemImpression({ item, itemIndex });
                                }}
                                onClick={() => {
                                    trackItemClick({
                                        item,
                                        itemIndex,
                                    });
                                }}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default HpSharedNewArrivalsModule;
