import { type FC } from 'react';
import { graphql, commitMutation } from 'react-relay';
import SV from 'server-vars';
import { VisibilityTracker } from 'dibs-visibility-tracker/exports/VisibilityTracker';

import getRelayEnvironment from '../../relayClientEnvironment';
import { type TrackErrorPageViewMutation } from './__generated__/TrackErrorPageViewMutation.graphql';

const trackErrorPageView = graphql`
    mutation TrackErrorPageViewMutation($input: TrackErrorPageInput!) {
        trackErrorPage(input: $input) {
            success
        }
    }
`;

type Props = {
    rumTransactionName?: string;
};

export const TrackErrorPageView: FC<Props> = props => {
    return (
        <VisibilityTracker
            onVisibilityChange={({ isVisible, disconnect }) => {
                /**
                 * It's possible that this error page is rendered, but not visible to the user.
                 * e.g. Pre-rendered checkout.
                 */
                if (isVisible) {
                    commitMutation<TrackErrorPageViewMutation>(getRelayEnvironment(), {
                        mutation: trackErrorPageView,
                        variables: {
                            input: {
                                pageType:
                                    props.rumTransactionName ||
                                    SV.get('rumTransactionName') ||
                                    'unknown',
                            },
                        },
                    });
                    disconnect();
                }
            }}
        />
    );
};
