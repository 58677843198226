import { type FC, type ComponentProps, type ReactNode, useCallback } from 'react';
import { useFragment, graphql } from 'react-relay';
import { trackEvent, eventNameConstants, interactionTypeConstants } from 'dibs-tracking';
import { ClientSuspense } from 'dibs-elements/src/ClientSuspense';

import { ItemTilesCarouselShimmer } from 'dibs-product-tile-carousel/exports/ItemTilesCarouselShimmer';
import { HpSharedHeader } from '../HpSharedHeader/HpSharedHeader';
import { HpSharedModuleContainer } from '../HpSharedModuleContainer/HpSharedModuleContainer';

import { dibsLazyClient } from 'dibs-lazy/exports/dibsLazyClient';
const HpSharedItemTilesCarousel = dibsLazyClient(() =>
    import(/* webpackChunkName: "HpSharedItemTilesCarousel" */ './HpSharedItemTilesCarousel').then(
        m => ({
            default: m.HpSharedItemTilesCarousel,
        })
    )
);

import { type HpSharedItemTilesCarouselModule_viewer$key } from './__generated__/HpSharedItemTilesCarouselModule_viewer.graphql';
import { type HpSharedItemTilesCarouselModule_items$key } from './__generated__/HpSharedItemTilesCarouselModule_items.graphql';
import { type HpSharedItemTilesCarouselModule_user$key } from './__generated__/HpSharedItemTilesCarouselModule_user.graphql';

const viewerFragment = graphql`
    fragment HpSharedItemTilesCarouselModule_viewer on Viewer
    @argumentDefinitions(
        fetchFavorites: { type: "Boolean!" }
        userId: { type: "String", defaultValue: "" }
    ) {
        ...HpSharedItemTilesCarousel_viewer
            @arguments(fetchFavorites: $fetchFavorites, userId: $userId)
    }
`;

const itemsFragment = graphql`
    fragment HpSharedItemTilesCarouselModule_items on Item
    @relay(plural: true)
    @argumentDefinitions(
        fetchFavorites: { type: "Boolean!" }
        userId: { type: "String", defaultValue: "" }
        isTrade: { type: "Boolean", defaultValue: false }
        priceBookName: { type: "String" }
    ) {
        ...HpSharedItemTilesCarousel_items
            @arguments(
                fetchFavorites: $fetchFavorites
                userId: $userId
                isTrade: $isTrade
                priceBookName: $priceBookName
            )
    }
`;

const userFragment = graphql`
    fragment HpSharedItemTilesCarouselModule_user on User {
        ...HpSharedItemTilesCarousel_user
    }
`;

const CAROUSEL_ITEMS_TO_SHOW_RESP = 5;
const CAROUSEL_ITEMS_TO_SHOW_MOBILE = 1.5;
const CAROUSEL_CAPACITY = 10;

type HpSharedItemTilesCarouselType = ComponentProps<typeof HpSharedItemTilesCarousel>;

export const HpSharedItemTilesCarouselModule: FC<{
    viewer?: HpSharedItemTilesCarouselModule_viewer$key | null;
    user?: HpSharedItemTilesCarouselModule_user$key | null;
    items: HpSharedItemTilesCarouselModule_items$key;
    isMobile: boolean;
    totalResults: HpSharedItemTilesCarouselType['totalResults'];
    trackingList: HpSharedItemTilesCarouselType['trackingList'];
    loadNext: HpSharedItemTilesCarouselType['loadNext'];
    onArrowClick: HpSharedItemTilesCarouselType['onArrowClick'];
    title?: ReactNode;
    viewMoreLink?: string;
    dataTn?: string;
}> = ({
    viewer: viewerRef,
    items: itemsRef,
    user: userRef,
    isMobile,
    totalResults,
    trackingList,
    loadNext,
    onArrowClick,
    title,
    viewMoreLink,
    dataTn,
}) => {
    const viewer = useFragment(viewerFragment, viewerRef);
    const user = useFragment(userFragment, userRef);
    const items = useFragment(itemsFragment, itemsRef);

    const fullWidth = !!isMobile;
    const gapSize = isMobile ? 'medium' : 'large';
    const itemsToShow = isMobile ? CAROUSEL_ITEMS_TO_SHOW_MOBILE : CAROUSEL_ITEMS_TO_SHOW_RESP;
    const showArrows = !isMobile;

    const onViewMoreClick: ComponentProps<typeof HpSharedHeader>['onViewMoreClick'] =
        useCallback(() => {
            trackEvent({
                eventName: eventNameConstants.EVENT_PRODUCT_INTERACTION,
                interaction_type:
                    interactionTypeConstants.INTERACTION_RECOMMENDATION_VIEW_ALL_CLICKED,
                trigger: trackingList,
            });
        }, [trackingList]);

    const shimmer = (
        <ItemTilesCarouselShimmer
            height={isMobile ? '286px' : '310px'}
            fullWidth={fullWidth}
            gapSize={gapSize}
            itemsToShow={itemsToShow}
            showArrows={showArrows}
        />
    );

    return (
        <HpSharedModuleContainer addTopGap={!title}>
            <HpSharedHeader
                title={title}
                viewMoreLink={viewMoreLink}
                onViewMoreClick={onViewMoreClick}
                dataTn={dataTn}
                size="larger"
            />
            <ClientSuspense fallback={shimmer}>
                {
                    // if module was rendered it's expected to have items. Render shimmer until items are loaded
                    items.length && viewer ? (
                        <HpSharedItemTilesCarousel
                            viewer={viewer}
                            user={user}
                            items={items}
                            isMobile={isMobile}
                            carouselCapacity={CAROUSEL_CAPACITY}
                            totalResults={totalResults}
                            trackingList={trackingList}
                            loadNext={loadNext}
                            onArrowClick={onArrowClick}
                            fullWidth={fullWidth}
                            gapSize={gapSize}
                            itemsToShow={itemsToShow}
                            showArrows={showArrows}
                        />
                    ) : (
                        shimmer
                    )
                }
            </ClientSuspense>
        </HpSharedModuleContainer>
    );
};
