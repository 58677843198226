import serverVars from 'server-vars';
import { useEffect } from 'react';
import { sessionStorage } from 'dibs-browser-storage';
import { PAGE_TYPE } from 'dibs-constants/exports/pageTypes';
import { getAbTestV2, trackAbTestV2Variant } from 'dibs-buyer-layout/exports/clientABTestV2';

const DWELLED_ON_PAGE_KEY = 'dwelledOnPage' as const;
const DWELLED_ON_PAGE_CONTROL_KEY = 'dwelledOnPageControl' as const;
const TEST_NAME = 'expandDwellTimeReg' as const;

const DWELL_ON_PDP_LIMIT_DESKTOP = 22000;
const DWELL_ON_PDP_LIMIT_MOBILE = 19000;

const DWELL_ON_HOMEPAGE_LIMIT = 11000;

const DWELL_ON_SB_LIMIT_DESKTOP = 28000;
const DWELL_ON_SB_LIMIT_MOBILE = 27000;

const isExpandDwellTimeRegVariant = (): boolean => getAbTestV2(TEST_NAME)?.variant === 'variant';

export const getDwelledOnPage = (): boolean => !!sessionStorage.getItem(DWELLED_ON_PAGE_KEY);
//Remove getDwelledOnPageControl after 'expandDwellTimeReg' AB test concludes.
export const getDwelledOnPageControl = (): boolean =>
    !!sessionStorage.getItem(DWELLED_ON_PAGE_CONTROL_KEY);

type DwellPageType = typeof PAGE_TYPE.HOME | typeof PAGE_TYPE.SEARCH | typeof PAGE_TYPE.PDP;

export const setDwelledOnPage = (pageType: DwellPageType): NodeJS.Timeout | undefined => {
    if (!getDwelledOnPage()) {
        const isMobile = serverVars.get('settings.isMobile');
        let dwellTimeLimit;
        switch (pageType) {
            case PAGE_TYPE.PDP:
                dwellTimeLimit = isMobile ? DWELL_ON_PDP_LIMIT_MOBILE : DWELL_ON_PDP_LIMIT_DESKTOP;
            case PAGE_TYPE.HOME:
                dwellTimeLimit = DWELL_ON_HOMEPAGE_LIMIT;
            case PAGE_TYPE.SEARCH:
                dwellTimeLimit = isMobile ? DWELL_ON_SB_LIMIT_MOBILE : DWELL_ON_SB_LIMIT_DESKTOP;
        }
        return setTimeout(() => {
            if (
                !getDwelledOnPageControl() &&
                (pageType === PAGE_TYPE.HOME || pageType === PAGE_TYPE.SEARCH)
            ) {
                trackAbTestV2Variant(TEST_NAME);
                if (isExpandDwellTimeRegVariant()) {
                    sessionStorage.setItem(DWELLED_ON_PAGE_KEY, true);
                }
                if (!isExpandDwellTimeRegVariant()) {
                    sessionStorage.setItem(DWELLED_ON_PAGE_CONTROL_KEY, true);
                }
            } else if (pageType === PAGE_TYPE.PDP) {
                sessionStorage.setItem(DWELLED_ON_PAGE_KEY, true);
            }
        }, dwellTimeLimit);
    }
    return undefined;
};

export const useDwelledOnPage = (pageType: DwellPageType): void => {
    useEffect(() => {
        const timeoutId = setDwelledOnPage(pageType);
        return () => clearTimeout(timeoutId);
    }, [pageType]);
};
