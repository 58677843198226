import { lazy, Suspense, type FC } from 'react';

const HpSharedAppBanner = lazy(
    () => import(/* webpackChunkName: "HpSharedAppBanner" */ './HpSharedAppBanner')
);

export const HpSharedAppBannerLazy: FC = () => {
    return (
        <Suspense fallback="">
            <HpSharedAppBanner />
        </Suspense>
    );
};
