import { type FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

import HpLazyImageWrapper from '../HpLazyImageWrapper/HpLazyImageWrapper';
import ResponsiveImage from '../ResponsiveImage';
import classnames from 'classnames';
import { Link } from 'dibs-elements/exports/Link';

import { type HpSharedTopHeroBannerImageItem_bannerItem$data as BannerItem } from './__generated__/HpSharedTopHeroBannerImageItem_bannerItem.graphql';

import styles from './HpSharedTopHeroBannerImageItem.scss';

type Props = {
    isVisible: boolean;
    handleClick: (item: BannerItem, index: number) => void;
    useLazyLoadImages?: boolean;
    index: number;
    bannerItem: BannerItem;
    itemId: string;
};

export const HpSharedTopHeroImageBannerItemComponent: FC<Props> = ({
    isVisible,
    handleClick,
    bannerItem,
    useLazyLoadImages,
    index,
    itemId,
}) => {
    const {
        isFullBleedImage,
        cmsId,
        linkData,
        imageUrl,
        mobileImageUrl,
        cta,
        imageHeight,
        imageWidth,
        mobileImageHeight,
        mobileImageWidth,
        imageCopy,
    } = bannerItem;

    const imgContainerClassName = classnames(styles.bannerImgContainer, {
        [styles.isVisible]: isVisible,
    });

    return (
        <>
            {imageCopy && (
                <span id={itemId} className={styles.imageCopy}>
                    {imageCopy}
                </span>
            )}
            {/* eslint-disable-next-line react/forbid-elements, jsx-a11y/anchor-has-content */}
            <Link
                key={cmsId}
                ariaLabel={cta || undefined}
                ariaDescribedBy={imageCopy ? itemId : undefined}
                href={linkData?.path || undefined}
                className={imgContainerClassName}
                onClick={() => handleClick(bannerItem, index)}
            >
                <HpLazyImageWrapper
                    useLazyLoad={useLazyLoadImages}
                    imageHeight={mobileImageHeight || imageHeight}
                    imageWidth={mobileImageWidth || imageWidth}
                >
                    <ResponsiveImage
                        // do not hide image if there is no copy bc automated a11y testing won't
                        // be able to detect this issue
                        ariaHidden={typeof imageCopy === 'string' && imageCopy !== ''}
                        className={classnames(styles.bannerImg, {
                            [styles.isFullBleed]: isFullBleedImage,
                        })}
                        src={imageUrl || mobileImageUrl || ''}
                    />
                </HpLazyImageWrapper>
            </Link>
        </>
    );
};
export const HpSharedTopHeroBannerImageItem = createFragmentContainer(
    HpSharedTopHeroImageBannerItemComponent,
    {
        bannerItem: graphql`
            fragment HpSharedTopHeroBannerImageItem_bannerItem on TopHeroBannerItem {
                cmsId
                imageCopy
                ... on TopHeroBannerItem @skip(if: $isMobile) {
                    imageUrl
                    imageHeight
                    imageWidth
                }
                ... on TopHeroBannerItem @include(if: $isMobile) {
                    mobileImageHeight
                    mobileImageWidth
                    mobileImageUrl
                }
                linkData {
                    path
                }
                cta
                isFullBleedImage
            }
        `,
    }
);
