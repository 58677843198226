import { type FC, type ComponentProps, type ReactNode } from 'react';
import classnames from 'classnames';
import { Link } from 'dibs-elements/exports/Link';
import { FormattedMessage } from 'dibs-react-intl';

import styles from './HpSharedHeader.scss';

type Props = {
    title?: ReactNode;
    subtitle?: string;
    viewMoreLink?: string | null;
    onViewMoreClick?: ComponentProps<typeof Link>['onClick'];
    dataTn?: string;
    size?: 'large' | 'larger';
};

export const HpSharedHeader: FC<Props> = ({
    title,
    subtitle,
    viewMoreLink,
    onViewMoreClick,
    dataTn,
    size = 'large',
}) =>
    title ? (
        <div className={classnames(styles.headerContainer, styles[size])}>
            <div className={styles.titleWrapper}>
                <div
                    className={classnames(styles.title, styles[size])}
                    data-tn={dataTn ? `${dataTn}-title` : null}
                >
                    {title}
                </div>
                {viewMoreLink && (
                    <Link
                        className={classnames(styles.viewMore, styles[size])}
                        href={viewMoreLink}
                        dataTn={dataTn ? `${dataTn}-view-more-link` : `view-more-link`}
                        onClick={onViewMoreClick}
                    >
                        <FormattedMessage id="HpSharedHeader.viewMore" defaultMessage="View More" />
                    </Link>
                )}
            </div>
            {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </div>
    ) : null;
