/**
 * @generated SignedSource<<a5eabd4d46362139ba126117c030a1eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedCollectionSpotlightItem_item$data = {
  readonly image: {
    readonly imageHeight: string | null;
    readonly imageUrl: string | null;
    readonly imageWidth: string | null;
  } | null;
  readonly linkData: {
    readonly " $fragmentSpreads": FragmentRefs<"SeoLink_linkData">;
  };
  readonly title: string | null;
  readonly " $fragmentType": "HpSharedCollectionSpotlightItem_item";
};
export type HpSharedCollectionSpotlightItem_item$key = {
  readonly " $data"?: HpSharedCollectionSpotlightItem_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedCollectionSpotlightItem_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedCollectionSpotlightItem_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LinkData",
      "kind": "LinkedField",
      "name": "linkData",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SeoLink_linkData"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionSpotlightImageType",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageWidth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageHeight",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CollectionSpotlightItem",
  "abstractKey": null
};

(node as any).hash = "40bad76b8bd9444995c8ae5867c09146";

export default node;
