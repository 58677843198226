import {
    useState,
    useMemo,
    useEffect,
    type CSSProperties,
    type MutableRefObject,
    type SyntheticEvent,
} from 'react';
import classnames from 'classnames';
import { getReadyState, READY_STATES } from './readyState';
import { type UnionMerge, omit } from './utils';
import { type TokenListGuard } from '../Common/TokenListGuard';
import { type Split, split } from '../Common/Split';

import styles from './main.scss';

const HANDLER_KEYS = {
    image: ['onLoad'] /* what to do with 'onError'? */,
    video: [
        'onCanPlay',
        'onCanPlayThrough',
        'onLoadedData',
        'onLoadedMetadata',
        'onLoadStart',
        'onSuspend',
        'onWaiting',
    ],
    '3d': ['onLoad'] /* 'onError', 'onPreload', 'onModel-Visibility', 'onProgress', */,
} as const;

/* useLazyLoadMedia types */

type Map<T extends readonly string[], V> = { [K in T[number]]: V };
type HandlerType = (e?: Event | SyntheticEvent) => void;
type HandlerKeys = typeof HANDLER_KEYS;
type HandlerMap = {
    [K in keyof HandlerKeys]: Map<HandlerKeys[K], HandlerType>;
};
type HandlerProps<T extends keyof HandlerMap, S extends string> = Omit<
    UnionMerge<HandlerMap[T]>,
    Split<S>[number]
>;

// do something funky stuff with mapped types to allow for arg discriminants to control return type
// https://stackoverflow.com/questions/61882444/how-to-combine-discriminated-unions-with-function-overloads-in-typescript
type UseLazyLoadMediaType = <Tag extends keyof HandlerMap, Excluded extends string = ''>(
    tag: Tag,
    args?: {
        ref?: MutableRefObject<null | HTMLElement>;
        excludedHandlers?: TokenListGuard<Excluded, HandlerKeys[Tag][number]>;
        /** must be string list matching handler keys for specified tag */
        className?: string;
        /** unit-less CSS `<integer>` pixel value as string */
        height?: string;
        /** unit-less CSS `<integer>` pixel value as string */
        width?: string;
        /** CSS `<length>` as string (e.g. `100px`, `30vw, etc.).
         *  may set `--lazy-media-container-width` or `--lazy-media-container-size` instead.
         *  @see https://developer.mozilla.org/en-US/docs/Web/CSS/length */
        maxWidth?: string;
        /** CSS `<length>` as string (e.g. `100px`, `30vw, etc.).
         *  may set `--lazy-media-container-height` or `--lazy-media-container-size` instead.
         *  @see https://developer.mozilla.org/en-US/docs/Web/CSS/length */
        maxHeight?: string;
        shimmer?: boolean;
    }
) => {
    handlerProps: HandlerProps<Tag, Excluded>;
    readyState: number; // use boolean based on tag type?
    styleProps: {
        width?: string;
        height?: string;
        style: CSSProperties;
        className: string;
    };
};

/* useLazyLoadMedia helpers */

const useMounted = (): boolean => {
    const [mounted, setMounted] = useState(false);
    useEffect(() => {
        setMounted(true);
        return () => setMounted(false);
    }, []);
    return mounted;
};

const stripPx = (arg: string | undefined): string | undefined => arg?.replace('px', '').trim();

/** construct a mapped handlers object using an array of keys and a handler */
function getHandlers<T extends readonly string[], V>(keys: T, handler: V): { [K in T[number]]: V } {
    return keys.reduce((handlers, item: T[number]) => {
        handlers[item] = handler;
        return handlers;
    }, {} as { [K in T[number]]: V });
}

// advantages of this method of lazy-loading -- DOM mutation is limited to style and class attributes. no new elements
// or layout re-calcs.
// TODO add params for legit lazy loading
//      `img`, `model-viewer`:  `loading="lazy"`
//      `video`: add/swap `src` or clone `source` children + `IntersectionObserver`

export const useLazyLoadMedia: UseLazyLoadMediaType = (
    tag,
    { ref, className, height, width, maxWidth, maxHeight, excludedHandlers, shimmer = true } = {}
) => {
    const mounted = useMounted();

    // read initial state from ref (if ref is defined before hook executes for whatever reason)
    const [readyState, setReadyState] = useState(getReadyState(ref?.current));

    // read mounted state from ref. this is needed for SSR -> hydration where an
    // element may change states or emit events before react mounts.
    useEffect(() => void (mounted && setReadyState(getReadyState(ref?.current))), [mounted, ref]);

    const handlers = useMemo(() => {
        const handleReadyState: HandlerType = e => {
            setReadyState(getReadyState(e?.target));
        };

        /*
        ABOUT THESE TYPE CASTS

        when handlerMap is accessed with an instance of tag that is a union (e.g.
        'video' | 'image'), TS assigns the type as union handlerMap['video'] |
        handlerMap['image'], which is not a helpful type: the union must be narrowed
        before TS will allow access to properties in the symmetric difference
        (properties not shared in common between elements of the union), which often
        requires user-defined assertions and/or type guards. JS allows access to
        undefined properties which enables asserting properties in the symmetric
        difference as optional without causing any runtime issues if/when they are
        accessed. the type casts below do just this to create more permissive return
        types that maintain their conditionality.
        
        the two casts below are chained to widen to all possible properties (`as
        UnionMerge<HandlerMap[keyof HandlerMap]>`) then narrow to all properties in the
        argument-conditioned mapped type (`as UnionMerge<HandlerMap[typeof tag]>`). this
        is done to maintain an "overlap" between the initial type and the subsequent
        casts and prevent an intermediate cast of `unknown`, which is totally cheating
        instead of partially cheating.
        */

        const handlerMap = {
            image: getHandlers(HANDLER_KEYS.image, handleReadyState),
            video: getHandlers(HANDLER_KEYS.video, handleReadyState),
            '3d': getHandlers(HANDLER_KEYS['3d'], handleReadyState),
        }[tag] as UnionMerge<HandlerMap[keyof HandlerMap]> as UnionMerge<HandlerMap[typeof tag]>;
        const widened = handlerMap;

        return widened;
    }, [tag]);

    // filter out excludedHandlers
    const handlerProps = useMemo(
        () => omit(handlers, split(excludedHandlers || '')),
        [excludedHandlers, handlers]
    );

    const styleProps = useMemo(() => {
        const formattedWidth = stripPx(width);
        const formattedHeight = stripPx(height);
        return {
            style: {
                ['--media-width']: formattedWidth,
                ['--media-height']: formattedHeight,
                ['--prop-max-width']: maxWidth?.trim(),
                ['--prop-max-height']: maxHeight?.trim(),
            } as CSSProperties,
            width: width?.toString(),
            height: height?.toString(),
            className: classnames(className, styles.placeholder, {
                [styles.shimmer]:
                    shimmer && (readyState === null || readyState < READY_STATES.HAVE_CURRENT_DATA),
            }),
        };
    }, [className, height, maxHeight, maxWidth, readyState, shimmer, width]);

    return { readyState, handlerProps, styleProps };
};
