/**
 * @generated SignedSource<<c46b70628db8e9807724a0cc5bf34b39>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedBestsellingDesignsCarousel_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedItemTilesCarouselModule_user">;
  readonly " $fragmentType": "HpSharedBestsellingDesignsCarousel_user";
};
export type HpSharedBestsellingDesignsCarousel_user$key = {
  readonly " $data"?: HpSharedBestsellingDesignsCarousel_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedBestsellingDesignsCarousel_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedBestsellingDesignsCarousel_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HpSharedItemTilesCarouselModule_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "c7e18c37619b7b4cf630301efb6a41c6";

export default node;
