/**
 * @generated SignedSource<<1d760846c36bf7cde0d728bfdd20f1e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedCollectionsModuleItem_item$data = {
  readonly imageUrl: string | null;
  readonly linkData: {
    readonly " $fragmentSpreads": FragmentRefs<"SeoLink_linkData">;
  };
  readonly title: string | null;
  readonly " $fragmentType": "HpSharedCollectionsModuleItem_item";
};
export type HpSharedCollectionsModuleItem_item$key = {
  readonly " $data"?: HpSharedCollectionsModuleItem_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedCollectionsModuleItem_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedCollectionsModuleItem_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LinkData",
      "kind": "LinkedField",
      "name": "linkData",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SeoLink_linkData"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "7fb73aec48046c0bc3116bc673ef91fc";

export default node;
