import { type FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { GridCol } from 'dibs-elements/exports/Grid';
import HpSharedModuleItemTile from '../HpSharedModuleItemTile/HpSharedModuleItemTile';

import styles from './HpSharedEditorialModuleItem.scss';

import { type HpSharedEditorialModuleItem_item$data } from './__generated__/HpSharedEditorialModuleItem_item.graphql';

type Props = {
    item: HpSharedEditorialModuleItem_item$data;
    index: number;
    onClick: () => void;
    useLazyLoadImages: boolean;
};

const HpSharedEditorialModuleItem: FC<Props> = ({
    item,
    index,
    onClick = () => {},
    useLazyLoadImages,
}) => {
    const { linkData, cta, description, imageUrl, imageHeight, imageWidth } = item;

    return (
        <GridCol tabletLandscape={6}>
            <div className={styles.itemWrapper}>
                <HpSharedModuleItemTile
                    linkData={linkData}
                    dataTn={`editorial-link-${index}`}
                    onClick={onClick}
                    title={item?.title}
                    description={description}
                    cta={cta}
                    imageUrl={imageUrl}
                    imgSrcSetWidths={[400, 640, 800]}
                    imgSizes="(max-width:768px) 95vw, 50vw"
                    imageHeight={imageHeight}
                    imageWidth={imageWidth}
                    imageClass={styles.tileImage}
                    imageOverlayClass={styles.tileImageOverlay}
                    useLazyLoadImages={useLazyLoadImages}
                />
            </div>
        </GridCol>
    );
};

export default createFragmentContainer(HpSharedEditorialModuleItem, {
    item: graphql`
        fragment HpSharedEditorialModuleItem_item on EditorialItem {
            title
            description
            cta
            imageUrl
            imageWidth
            imageHeight
            linkData {
                ...HpSharedModuleItemTile_linkData
            }
        }
    `,
});
