import { type FC, useRef } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { GridCol, GridRow } from 'dibs-elements/exports/Grid';
import { SeoButton } from 'dibs-seo/exports/SeoButton';
import VisibilityTracker from 'dibs-visibility-tracker/exports/VisibilityTracker';
import ResponsiveImage from '../ResponsiveImage';
import HpLazyImageWrapper from '../HpLazyImageWrapper/HpLazyImageWrapper';
import { HpSharedModuleContainer } from '../HpSharedModuleContainer/HpSharedModuleContainer';
import HpSharedCollectionSpotlightItem from './HpSharedCollectionSpotlightItem';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';
import {
    trackPromoClick,
    trackPromoImpressions,
    buildPromoTrackingObject,
} from '../helpers/promoTrackingHelpers';
import { trackProductClick, trackProductImpression } from './helpers/collectionSpotlightTracking';
import { trackModuleLocation, trackModule } from '../helpers/moduleLocationTracking';
import { HpSharedHeader } from '../HpSharedHeader/HpSharedHeader';

import styles from './HpSharedCollectionSpotlightModule.scss';

import { type HpSharedCollectionSpotlightModule_componentModule$data } from './__generated__/HpSharedCollectionSpotlightModule_componentModule.graphql';

type Props = {
    componentModule: HpSharedCollectionSpotlightModule_componentModule$data;
    isMobile: boolean;
    useLazyLoadImages: boolean;
    moduleIndex: number;
    totalModules: number;
};

const HpSharedCollectionSpotlightModule: FC<Props> = ({
    componentModule,
    isMobile,
    useLazyLoadImages,
    moduleIndex,
    totalModules,
}) => {
    const impressionFiredRef = useRef(false);
    const productsRowRef = useRef<HTMLDivElement>(null);

    if (!componentModule) {
        return null;
    }

    const {
        isCollection,
        image,
        collectionImage,
        collectionPhoneImage,
        profileImage,
        videoEmbedId,
        description,
        title,
        items,
        linkText,
        cta,
        linkData,
    } = componentModule?.messages || {};
    const _collectionImage = isMobile ? collectionPhoneImage : collectionImage;

    if (!linkData) {
        return null;
    }

    const promoTrackingObject = buildPromoTrackingObject({
        item: componentModule?.messages,
        id: 'collection-spotlight',
        name: 'Collection Spotlight',
    });

    const fireImpressionTracking = (): void => {
        if (!impressionFiredRef.current) {
            trackPromoImpressions([promoTrackingObject]);
            impressionFiredRef.current = true;
        }
    };

    const trackModLocation = (): void =>
        trackModuleLocation({
            label: 'collection spotlight module',
            moduleIndex,
            totalModules,
        });

    const productItems = (items || []).filter(filterNulls);

    return (
        <HpSharedModuleContainer className={styles.wrapper}>
            <HpSharedHeader title={title} />
            <GridRow>
                {isCollection ? (
                    <div className={styles.collectionImageWrapper}>
                        <HpLazyImageWrapper
                            useLazyLoad={useLazyLoadImages}
                            imageWidth={_collectionImage?.imageWidth}
                            imageHeight={_collectionImage?.imageHeight}
                            onImageLoaded={fireImpressionTracking}
                        >
                            <ResponsiveImage
                                ariaHidden
                                className={styles.collectionImage}
                                src={_collectionImage?.imageUrl || ''}
                                srcSetWidths={[400, 640, 960, 1280, 1440]}
                            />
                        </HpLazyImageWrapper>
                        <div className={styles.hidden}>{description}</div>
                    </div>
                ) : (
                    <>
                        <GridCol
                            tabletLandscape={6}
                            tabletLandscapeOptions={{
                                verticalAlign: 'stretch',
                            }}
                        >
                            <div className={styles.leftBlock}>
                                <div className={styles.profileImageWrapper}>
                                    <HpLazyImageWrapper
                                        useLazyLoad={useLazyLoadImages}
                                        imageWidth={profileImage?.imageWidth}
                                        imageHeight={profileImage?.imageHeight}
                                        onImageLoaded={fireImpressionTracking}
                                    >
                                        <ResponsiveImage
                                            ariaHidden
                                            className={styles.profileImage}
                                            src={profileImage?.imageUrl || ''}
                                            srcSetWidths={[120, 160]}
                                        />
                                    </HpLazyImageWrapper>
                                </div>
                                {description}
                            </div>
                        </GridCol>
                        <GridCol
                            tabletLandscape={6}
                            tabletLandscapeOptions={{
                                order: 'last',
                            }}
                            mobileOptions={{
                                order: 'first',
                            }}
                        >
                            <div className={styles.rightBlock}>
                                {videoEmbedId ? (
                                    <div className={styles.iframeWrapper}>
                                        <iframe
                                            title="collection-video-frame"
                                            src={`https://player.vimeo.com/video/${videoEmbedId}`}
                                            className={styles.player}
                                            allowFullScreen
                                        />
                                    </div>
                                ) : (
                                    <HpLazyImageWrapper
                                        useLazyLoad={useLazyLoadImages}
                                        imageWidth={image?.imageWidth}
                                        imageHeight={image?.imageHeight}
                                    >
                                        <ResponsiveImage
                                            ariaHidden
                                            className={styles.collectionImage}
                                            src={image?.imageUrl || ''}
                                            srcSetWidths={[400, 700]}
                                        />
                                    </HpLazyImageWrapper>
                                )}
                            </div>
                        </GridCol>
                    </>
                )}
            </GridRow>
            <GridRow>
                <div className={styles.productsRow} ref={productsRowRef}>
                    <VisibilityTracker
                        elementRef={productsRowRef}
                        onVisibilityChange={({ isVisible }) => {
                            if (isVisible) {
                                trackProductImpression({ items: productItems });
                            }
                        }}
                    />
                    {productItems.map((item, index) => {
                        return (
                            <GridCol key={`${index}-${title}`} desktop={2} tabletLandscape={4}>
                                <HpSharedCollectionSpotlightItem
                                    item={item}
                                    useLazyLoadImages={useLazyLoadImages}
                                    onClick={() => {
                                        trackProductClick({ item, index });
                                        trackModLocation();
                                    }}
                                />
                            </GridCol>
                        );
                    })}
                    <GridCol
                        desktop={2}
                        tabletLandscape={4}
                        desktopOptions={{ verticalAlign: 'center', order: 'last' }}
                        tabletLandscapeOptions={{
                            verticalAlign: 'center',
                            order: 'last',
                        }}
                    >
                        <div className={styles.linkText}>{linkText}</div>
                        <SeoButton
                            type="secondary"
                            dataTn="maker-cta"
                            linkData={linkData}
                            size="large"
                            fullWidth
                            onClick={() => {
                                trackPromoClick(promoTrackingObject);
                                trackModLocation();
                                trackModule(componentModule.cmsDisplayTitle);
                            }}
                        >
                            {cta}
                        </SeoButton>
                    </GridCol>
                </div>
            </GridRow>
        </HpSharedModuleContainer>
    );
};

export default createFragmentContainer(HpSharedCollectionSpotlightModule, {
    componentModule: graphql`
        fragment HpSharedCollectionSpotlightModule_componentModule on CollectionSpotlightModule {
            cmsDisplayTitle
            messages(userType: $userType) {
                title
                description
                isCollection
                videoEmbedId
                linkText
                cta
                collectionImage {
                    imageUrl
                    imageWidth
                    imageHeight
                }
                collectionPhoneImage {
                    imageUrl
                    imageWidth
                    imageHeight
                }
                profileImage {
                    imageUrl
                    imageWidth
                    imageHeight
                }
                image {
                    imageUrl
                    imageWidth
                    imageHeight
                }
                linkData {
                    path
                    ...SeoButton_linkData
                }
                items {
                    title
                    itemId
                    contemporaryTrackingString
                    linkData {
                        path
                    }
                    ...HpSharedCollectionSpotlightItem_item
                }
            }
        }
    `,
});
