import { type FC } from 'react';
import { useFragment, graphql } from 'react-relay';

import { HpSharedMostSavedItemsCarousel } from '../HpSharedMostSavedItemsCarousel/HpSharedMostSavedItemsCarousel';
import { HpSharedExcellentValuesCarousel } from '../HpSharedExcellentValuesCarousel/HpSharedExcellentValuesCarousel';
import { HpSharedBestsellingDesignsCarousel } from '../HpSharedBestsellingDesignsCarousel/HpSharedBestsellingDesignsCarousel';

import { type HpSharedLocationsModule_viewer$key } from './__generated__/HpSharedLocationsModule_viewer.graphql';
import { type HpSharedLocationsModule_user$key } from './__generated__/HpSharedLocationsModule_user.graphql';

const viewerFragment = graphql`
    fragment HpSharedLocationsModule_viewer on Viewer {
        ...HpSharedMostSavedItemsCarousel_viewer @include(if: $isClient)
        ...HpSharedExcellentValuesCarousel_viewer @include(if: $isClient)
        ...HpSharedBestsellingDesignsCarousel_viewer @include(if: $isClient)
    }
`;

const userFragment = graphql`
    fragment HpSharedLocationsModule_user on User {
        ...HpSharedMostSavedItemsCarousel_user @include(if: $isClient)
        ...HpSharedExcellentValuesCarousel_user @include(if: $isClient)
        ...HpSharedBestsellingDesignsCarousel_user @include(if: $isClient)
    }
`;

export const HpSharedLocationsModule: FC<{
    viewer: HpSharedLocationsModule_viewer$key;
    user?: HpSharedLocationsModule_user$key | null;
    isMobile: boolean;
    isClient?: boolean;
}> = ({ viewer: viewerRef, user: userRef, isMobile, isClient }) => {
    const viewer = useFragment(viewerFragment, viewerRef);
    const user = useFragment(userFragment, userRef);

    return (
        <>
            {isClient && (
                <>
                    <HpSharedMostSavedItemsCarousel
                        viewer={viewer}
                        user={user}
                        isMobile={isMobile}
                    />
                    <HpSharedExcellentValuesCarousel
                        viewer={viewer}
                        user={user}
                        isMobile={isMobile}
                    />
                    <HpSharedBestsellingDesignsCarousel
                        viewer={viewer}
                        user={user}
                        isMobile={isMobile}
                    />
                </>
            )}
        </>
    );
};
