import { graphql, readInlineData } from 'react-relay';

import {
    trackingConstants,
    eventNameConstants,
    stepInteractionConstants,
    trackEcommerce,
    trackEvent,
    type TrackingObject,
} from 'dibs-tracking';

import { type newArrivalsTracking_personalizationModule$key } from './__generated__/newArrivalsTracking_personalizationModule.graphql';
import { type newArrivalsTracking_item$key } from './__generated__/newArrivalsTracking_item.graphql';

const { EVENT_SELECT_ITEM, EVENT_VIEW_ITEM_LIST, EVENT_PRODUCT_INTERACTION } = eventNameConstants;
const { STEP_INTERACTION_VIEW_ALL_CLICK } = stepInteractionConstants;
const { ECOM_PRODUCT_CLICK, ECOM_PRODUCT_IMPRESSION } = trackingConstants;

const NEW_ARRIVALS = 'New Arrivals';

const personalizationModuleFragment = graphql`
    fragment newArrivalsTracking_personalizationModule on PersonalizationModule @inline {
        categoryTitle
    }
`;

const itemFragment = graphql`
    fragment newArrivalsTracking_item on Item @inline {
        browseUrl
        categoryCode
        ecommerceTrackingParams
        serviceId
    }
`;

const getBaseEventTrackingObject = (): TrackingObject => ({
    eventName: EVENT_PRODUCT_INTERACTION,
    interaction_type: `${NEW_ARRIVALS} module`,
    step_interaction_name: STEP_INTERACTION_VIEW_ALL_CLICK,
    isInteractiveEvent: true,
});

export const trackViewMoreClick = (): void => {
    trackEvent({
        ...getBaseEventTrackingObject(),
        trigger: NEW_ARRIVALS,
    });
};

export const trackCategoryClick = ({
    personalizationModule: personalizationModuleRef,
}: {
    personalizationModule: newArrivalsTracking_personalizationModule$key | null;
}): void => {
    const personalizationModule = readInlineData(
        personalizationModuleFragment,
        personalizationModuleRef
    );

    trackEvent({
        ...getBaseEventTrackingObject(),
        trigger: `Category - ${personalizationModule?.categoryTitle}`,
    });
};

export const trackItemClick = ({
    item: itemRef,
    itemIndex,
}: {
    item: newArrivalsTracking_item$key | null;
    itemIndex: number;
}): void => {
    const item = readInlineData(itemFragment, itemRef);
    const { browseUrl, categoryCode, ecommerceTrackingParams, serviceId } = item || {};

    trackEcommerce({
        type: ECOM_PRODUCT_CLICK,
        eventName: EVENT_SELECT_ITEM,
        trigger: NEW_ARRIVALS,
        actionField: { list: NEW_ARRIVALS, itemId: serviceId },
        products: [
            {
                position: itemIndex + 1,
                categoryCode,
                category: browseUrl,
                ...ecommerceTrackingParams,
            },
        ],
    });
};

export const trackModuleImpression = (): void => {
    trackEcommerce({
        type: ECOM_PRODUCT_IMPRESSION,
        eventName: EVENT_VIEW_ITEM_LIST,
        trigger: NEW_ARRIVALS,
        actionField: { list: NEW_ARRIVALS },
        products: [],
    });
};

export const trackItemImpression = ({
    item: itemRef,
    itemIndex,
}: {
    item: newArrivalsTracking_item$key | null;
    itemIndex: number;
}): void => {
    const item = readInlineData(itemFragment, itemRef);
    const { browseUrl, categoryCode, ecommerceTrackingParams } = item || {};

    trackEcommerce({
        type: ECOM_PRODUCT_IMPRESSION,
        eventName: EVENT_VIEW_ITEM_LIST,
        trigger: NEW_ARRIVALS,
        products: [
            {
                position: itemIndex + 1,
                list: NEW_ARRIVALS,
                categoryCode: categoryCode,
                category: browseUrl,
                ...ecommerceTrackingParams,
            },
        ],
    });
};
