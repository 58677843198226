import { useId, type FC } from 'react';
import classnames from 'classnames';
import { SeoLink } from 'dibs-seo/exports/SeoLink';
import slugify from 'dibs-slugify/exports/slugify';

import HpLazyImageWrapper from '../HpLazyImageWrapper/HpLazyImageWrapper';
import ResponsiveImage from '../ResponsiveImage';
import { type Item, type LinkData } from './HpSharedFeaturedModule';

import styles from './HpSharedFeaturedModuleItem.scss';

type Props = {
    item: Item;
    useLazyLoadImages: boolean;
    onClick: () => void;
};

const stripHtml = (html: string): string => html.replace(/<\/?[^>]+(>|$)/g, '');

const HpSharedFeaturedModuleItem: FC<Props> = ({ item, onClick, useLazyLoadImages }) => {
    const { title = '', linkData, imageUrl, cta, backgroundColor, textColor } = item || {};
    const linkId = slugify(`featured-${title}-${useId()}`);

    return (
        <div className={classnames(styles.item, 'colXs6')}>
            <SeoLink
                className={styles.link}
                style={{ ...(backgroundColor && { backgroundColor }) }}
                linkData={linkData as LinkData}
                onClick={onClick}
            >
                {title && (
                    <span
                        id={linkId}
                        className={styles.title}
                        style={{ ...(textColor && { color: textColor }) }}
                    >
                        <span
                            dangerouslySetInnerHTML={{ __html: title }}
                            data-tn={slugify(`featured-${stripHtml(title)}-title`)}
                        />
                    </span>
                )}
                <HpLazyImageWrapper
                    useLazyLoad={useLazyLoadImages}
                    className={styles.imageContainer}
                >
                    <ResponsiveImage
                        className={styles.image}
                        ariaLabelledBy={title ? linkId : undefined}
                        src={imageUrl || ''}
                        srcSetWidths={[100, 120, 150]}
                    />
                </HpLazyImageWrapper>
                <span className={styles.cta} style={{ ...(textColor && { color: textColor }) }}>
                    {cta}
                </span>
            </SeoLink>
        </div>
    );
};

export { HpSharedFeaturedModuleItem };
