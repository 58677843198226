/**
 * @generated SignedSource<<1f11fbc7ed1084f7ab5807d3fd9d9794>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Tile_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Info_viewer">;
  readonly " $fragmentType": "Tile_viewer";
};
export type Tile_viewer$key = {
  readonly " $data"?: Tile_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Tile_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "fetchRegionalInfo"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "userCountryCode"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "userZipCode"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Tile_viewer",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "fetchRegionalInfo",
          "variableName": "fetchRegionalInfo"
        },
        {
          "kind": "Variable",
          "name": "userCountryCode",
          "variableName": "userCountryCode"
        },
        {
          "kind": "Variable",
          "name": "userZipCode",
          "variableName": "userZipCode"
        }
      ],
      "kind": "FragmentSpread",
      "name": "Info_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "1215e4a466f1ba82dd6302bbf267ff63";

export default node;
