/**
 * Copied from NftItemTileMedia.tsx
 * Should be placed in own package before merge
 */

import { type FunctionComponent, useEffect, useRef, useCallback, useState } from 'react';
import classnames from 'classnames';
import { ItemMediaSpinner } from 'dibs-elements/exports/ItemMediaSpinner';
import { useLazyLoadMedia, READY_STATES } from 'dibs-elements/exports/useLazyLoadMedia';
import {
    VisibilityTracker,
    type VisibilityChangeCallback,
} from 'dibs-visibility-tracker/exports/VisibilityTracker';
import { type Property } from 'csstype';
import dibsCss from 'dibs-css';
import styles from './VideoTile.scss';

export type VideoTileProps = {
    sourceUrl: string;
    animate?: boolean;
    posterOverride?: string;
    resetOnPause?: boolean;
    observerOptions?: IntersectionObserverInit;
    objectFit?: Property.ObjectFit;
    objectPosition?: Property.ObjectPosition;
    controls?: boolean;
    loop?: boolean;
    autoPlay?: boolean;
    muted?: boolean;
    onEnded?: () => void;
    onPause?: () => void;
    onPlay?: () => void;
    noBackground?: boolean;
    noSpinner?: boolean;
};

export const VideoTile: FunctionComponent<VideoTileProps> = ({
    sourceUrl,
    animate = true,
    resetOnPause = false,
    posterOverride,
    observerOptions = {
        rootMargin: `15% 0px 15% 0px`, // start loading just outside the viewport
    },
    objectFit = 'cover',
    objectPosition = 'initial',
    controls = false,
    loop = true,
    muted = true,
    autoPlay = false,
    onPause = () => {},
    onPlay = () => {},
    onEnded = () => {},
    noBackground = false, // remove horizontal stripes above and below VideoTile
    noSpinner = false, // if videoTile is big enough, double spinner is  visible
}) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const sourceRef = useRef<HTMLSourceElement | null>(null);
    const [isPosterReplaced, setIsPosterReplaced] = useState(false);
    const lazyLoad = useLazyLoadMedia('video', {
        ref: videoRef,
        excludedHandlers: 'onSuspend onWaiting',
        shimmer: false,
    });

    useEffect(() => {
        if (videoRef.current && lazyLoad.readyState >= READY_STATES.HAVE_CURRENT_DATA) {
            // setting playback time on the video replaces the poster with the frame at the
            // given time
            if (!posterOverride) {
                videoRef.current.currentTime = 0;
            }
            setIsPosterReplaced(true);
        }
    }, [lazyLoad.readyState, posterOverride]);

    useEffect(() => {
        // only autoplay if enough data has been loaded to replace the poster
        if (!isPosterReplaced) return;

        const ref = videoRef.current;
        let playPromise: Promise<void> | undefined;

        if (animate) {
            playPromise = ref?.play();
        } else if (resetOnPause) {
            ref?.load();
        }

        // eslint-disable-next-line consistent-return
        return () => {
            playPromise?.then(() => ref?.pause());
        };
    }, [resetOnPause, animate, isPosterReplaced]);

    const handleVisible: (args: VisibilityChangeCallback) => void = useCallback(
        args => {
            if (!(sourceRef.current && videoRef.current)) return;
            if (args.isVisible) {
                // start loading data if video is in viewport
                sourceRef.current.src = sourceUrl;
                videoRef.current.load();
            }
        },
        [sourceUrl]
    );

    return (
        <>
            <VisibilityTracker
                elementRef={videoRef}
                onVisibilityChange={handleVisible}
                watchAfterFirstVisible={false}
                observerOptions={observerOptions}
            />
            {!noSpinner && <ItemMediaSpinner isOpen={!isPosterReplaced} />}
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
                {...lazyLoad.handlerProps}
                style={{
                    objectFit,
                    objectPosition,
                }}
                poster={posterOverride}
                ref={videoRef}
                className={classnames(dibsCss.absolute, dibsCss.wFull, {
                    [dibsCss.bgDolphin]: !noBackground,
                    [dibsCss.hFull]: !noBackground,
                    [styles.centerWithoutBackground]: noBackground,
                })}
                onPause={onPause}
                onPlay={onPlay}
                onEnded={() => {
                    onEnded();
                    if (!loop && videoRef?.current) {
                        videoRef.current.load();
                    }
                }}
                controls={controls}
                loop={loop}
                muted={muted}
                playsInline
                autoPlay={autoPlay}
                preload="auto"
            >
                <source ref={sourceRef} type="video/mp4" />
            </video>
        </>
    );
};
