/**
 * @generated SignedSource<<c5d0bcbc48d51514c175499d28720c73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useRecentHistoryList_user$data = {
  readonly recentHistoryItems: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly browseUrl: string | null;
        readonly categoryCode: string | null;
        readonly categoryPath: string | null;
        readonly contemporaryTrackingString: string;
        readonly ecommerceTrackingParams: any | null;
        readonly firstPhotoWebPath: string | null;
        readonly pdpURL: string | null;
        readonly seller: {
          readonly serviceId: string | null;
        } | null;
        readonly serviceId: string | null;
        readonly title: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"AuctionPricePaddle_item">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "useRecentHistoryList_user";
};
export type useRecentHistoryList_user$key = {
  readonly " $data"?: useRecentHistoryList_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"useRecentHistoryList_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 0,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "excludeItemPks"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useRecentHistoryList_user",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "excludeItemPks",
          "variableName": "excludeItemPks"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "count"
        }
      ],
      "concreteType": "RecentHistoryItemsConnection",
      "kind": "LinkedField",
      "name": "recentHistoryItems",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RecentHistoryItemsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Item",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AuctionPricePaddle_item"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ecommerceTrackingParams",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "contemporaryTrackingString",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "size",
                      "value": "small"
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "firstPhotoWebPath",
                  "storageKey": "firstPhotoWebPath(size:\"small\")"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pdpURL",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "browseUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "categoryCode",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "categoryPath",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Seller",
                  "kind": "LinkedField",
                  "name": "seller",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "30ce56b6f4e196cef063ce9e517b9904";

export default node;
