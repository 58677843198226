/**
 * @generated SignedSource<<11de832212ff9d6c92c5875983b48642>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedHeroModule_componentModule$data = {
  readonly title: string | null;
  readonly viewMoreLink: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedHeroModuleControl_componentModule">;
  readonly " $fragmentType": "HpSharedHeroModule_componentModule";
};
export type HpSharedHeroModule_componentModule$key = {
  readonly " $data"?: HpSharedHeroModule_componentModule$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedHeroModule_componentModule">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isEditorsPickHpVariant"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedHeroModule_componentModule",
  "selections": [
    {
      "condition": "isEditorsPickHpVariant",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HpSharedHeroModuleControl_componentModule"
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewMoreLink",
      "storageKey": null
    }
  ],
  "type": "HeroModule",
  "abstractKey": null
};

(node as any).hash = "920144792ccdce94743161d40621a6f8";

export default node;
