const MOBILE_ITEMS_PER_PAGE = 2.5;
const TABLET_ITEMS_PER_PAGE = 4;
const DESKTOP_ITEMS_PER_PAGE = 6;

const DEFAULT_NEW_ARRIVALS_PER_PAGE = 3;
const MOBILE_NEW_ARRIVALS_PER_PAGE = 1;

const LAZY_LOAD_BEGINS_AT = {
    MOBILE: 2,
    TABLET: 3,
    DESKTOP: 3,
};

export {
    MOBILE_ITEMS_PER_PAGE,
    TABLET_ITEMS_PER_PAGE,
    DESKTOP_ITEMS_PER_PAGE,
    DEFAULT_NEW_ARRIVALS_PER_PAGE,
    MOBILE_NEW_ARRIVALS_PER_PAGE,
    LAZY_LOAD_BEGINS_AT,
};
