/**
 * @generated SignedSource<<5f64f2c4c4246519130def5deb8f9b23>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedHeroModule_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedHeroModuleVariant_user">;
  readonly " $fragmentType": "HpSharedHeroModule_user";
};
export type HpSharedHeroModule_user$key = {
  readonly " $data"?: HpSharedHeroModule_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedHeroModule_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isEditorsPickHpVariant"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedHeroModule_user",
  "selections": [
    {
      "condition": "isEditorsPickHpVariant",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HpSharedHeroModuleVariant_user"
        }
      ]
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "c3e9c9a7bbd44fb9c24844389b034a5c";

export default node;
