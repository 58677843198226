import { type FC, type ComponentProps, useCallback } from 'react';
import { useFragment, graphql, usePaginationFragment } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';

import { HpSharedItemTilesCarouselModule } from '../HpSharedItemTilesCarouselModule/HpSharedItemTilesCarouselModule';

import {
    trackEvent,
    eventNameConstants,
    interactionTypeConstants,
    stepInteractionConstants,
    triggerConstants,
} from 'dibs-tracking';

import { flattenConnectionNodes } from 'dibs-ts-utils/exports/flattenConnectionNodes';
import { type HpSharedBestsellingDesignsCarousel_viewer$key } from './__generated__/HpSharedBestsellingDesignsCarousel_viewer.graphql';
import { type HpSharedBestsellingDesignsCarousel_user$key } from './__generated__/HpSharedBestsellingDesignsCarousel_user.graphql';

const viewerFragment = graphql`
    fragment HpSharedBestsellingDesignsCarousel_viewer on Viewer
    @refetchable(queryName: "HpSharedBestsellingDesignsCarouselPaginationQuery")
    @argumentDefinitions(count: { type: "Int", defaultValue: 6 }, cursor: { type: "String" }) {
        bestsellingDesigns: itemSearchItems(
            first: $count
            after: $cursor
            uriRef: "/collections/best-sellers/?sort=trending"
            censored: true
            userCountryCode: $userCountryCode
            priceBookName: $priceBookName
        ) @connection(key: "HpSharedBestsellingDesignsCarousel_bestsellingDesigns", filters: []) {
            edges {
                node {
                    ...HpSharedItemTilesCarouselModule_items
                        @arguments(
                            fetchFavorites: $hasUserId
                            userId: $userId
                            isTrade: $isTrade
                            priceBookName: $priceBookName
                        )
                }
            }
            totalResults
        }
        ...HpSharedItemTilesCarouselModule_viewer
            @arguments(fetchFavorites: $hasUserId, userId: $userId)
    }
`;

const userFragment = graphql`
    fragment HpSharedBestsellingDesignsCarousel_user on User {
        ...HpSharedItemTilesCarouselModule_user
    }
`;

export const HpSharedBestsellingDesignsCarousel: FC<{
    viewer?: HpSharedBestsellingDesignsCarousel_viewer$key | null;
    user?: HpSharedBestsellingDesignsCarousel_user$key | null;
    isMobile: boolean;
}> = ({ viewer: viewerRef, user: userRef, isMobile }) => {
    const { data: viewer, loadNext } = usePaginationFragment(viewerFragment, viewerRef);
    const user = useFragment(userFragment, userRef);

    const onArrowClick: ComponentProps<typeof HpSharedItemTilesCarouselModule>['onArrowClick'] =
        useCallback(() => {
            trackEvent({
                eventName: eventNameConstants.EVENT_PRODUCT_INTERACTION,
                interaction_type: interactionTypeConstants.INTERACTION_TYPE_HOMEPAGE,
                step_interaction_name:
                    stepInteractionConstants.STEP_INTERACTION_BESTSELLERS_ARROW_CLICK,
                trigger: triggerConstants.TRIGGER_BESTSELLERS,
            });
        }, []);

    const items = flattenConnectionNodes(viewer?.bestsellingDesigns);
    const totalResults = viewer?.bestsellingDesigns?.totalResults || 0;

    return (
        <HpSharedItemTilesCarouselModule
            viewer={viewer}
            user={user}
            items={items}
            isMobile={isMobile}
            totalResults={totalResults}
            trackingList={triggerConstants.TRIGGER_BESTSELLERS}
            loadNext={loadNext}
            onArrowClick={onArrowClick}
            title={
                <FormattedMessage
                    id="HpSharedBestsellingDesignsCarousel.title"
                    defaultMessage="Bestselling Designs"
                />
            }
            viewMoreLink="/collections/best-sellers/?sort=newest"
            dataTn="excellent-values"
        />
    );
};
