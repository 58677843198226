/**
 * @generated SignedSource<<934e5f0d01155dbcdcbbf957372f4b0b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type recommendedItemTracking_item$data = {
  readonly browseUrl: string | null;
  readonly categoryCode: string | null;
  readonly contemporaryTrackingString: string;
  readonly seller: {
    readonly serviceId: string | null;
  } | null;
  readonly serviceId: string | null;
  readonly title: string | null;
  readonly " $fragmentType": "recommendedItemTracking_item";
};
export type recommendedItemTracking_item$key = {
  readonly " $data"?: recommendedItemTracking_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"recommendedItemTracking_item">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "recommendedItemTracking_item"
};

(node as any).hash = "75ff6def4e42f13d6236c797d89f5ad0";

export default node;
