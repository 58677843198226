/**
 * @generated SignedSource<<264bccbf9fb75bb3882e903509c0a23f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AuctionPricePaddle_item$data = {
  readonly isAuctionEnabled: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"useAuctionPricing_item">;
  readonly " $fragmentType": "AuctionPricePaddle_item";
};
export type AuctionPricePaddle_item$key = {
  readonly " $data"?: AuctionPricePaddle_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuctionPricePaddle_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "page"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuctionPricePaddle_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAuctionEnabled",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "page",
          "variableName": "page"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useAuctionPricing_item"
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "7b37463336bb0d3b6cc6dcaf87ceb686";

export default node;
