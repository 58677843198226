/**
 * @generated SignedSource<<bdf9393b56ceade68f7de2b8044d7195>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedHeart_item$data = {
  readonly serviceId: string | null;
  readonly " $fragmentType": "SharedHeart_item";
};
export type SharedHeart_item$key = {
  readonly " $data"?: SharedHeart_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedHeart_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedHeart_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "6973461d4f4242f3cbaf7200c2cb70b1";

export default node;
