import { type FC } from 'react';
import classnames from 'classnames';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { SeoButton } from 'dibs-seo/exports/SeoButton';

import styles from './HpSharedHeroBannerText.scss';

import { type HpSharedHeroBannerText_heroBannerSettings$data } from './__generated__/HpSharedHeroBannerText_heroBannerSettings.graphql';
import { type HpSharedHeroBannerText_linkData$data } from './__generated__/HpSharedHeroBannerText_linkData.graphql';

type Props = {
    heroBannerSettings: HpSharedHeroBannerText_heroBannerSettings$data | null | undefined;
    linkData: HpSharedHeroBannerText_linkData$data | null | undefined;
    cta: string | null;
    onClick: () => void;
    className?: string;
};

const HpSharedHeroBannerText: FC<Props> = ({
    heroBannerSettings,
    linkData,
    cta,
    className,
    onClick,
}) => {
    const { textAlignment, contentColor, eyeBrowText, headerText, dekText, ctaStyle } =
        heroBannerSettings || {};

    const textClasses = classnames(className, styles.text, {
        [styles.textAlignCenter]: textAlignment === 'center',
        [styles.contentColorWhite]: contentColor === 'white',
    });

    const ctaClasses = classnames(styles.cta, {
        [styles.outline]: ctaStyle === 'outline',
        [styles.contentColorWhite]: contentColor === 'white',
    });

    const linkPath = linkData?.path;

    return (
        <div className={textClasses}>
            <div className={styles.eyeBrow}>{eyeBrowText}</div>
            <div
                className={styles.header}
                dangerouslySetInnerHTML={headerText ? { __html: headerText } : undefined}
            />
            <div
                className={styles.dek}
                dangerouslySetInnerHTML={dekText ? { __html: dekText } : undefined}
            />
            <div>
                {linkPath && cta && (
                    <SeoButton
                        onClick={onClick}
                        dataTn="hero-banner-cta"
                        className={ctaClasses}
                        linkData={linkData || { path: null }}
                        type={ctaStyle === 'outline' ? 'secondary' : 'primary'}
                    >
                        {cta}
                    </SeoButton>
                )}
            </div>
        </div>
    );
};

export default createFragmentContainer(HpSharedHeroBannerText, {
    heroBannerSettings: graphql`
        fragment HpSharedHeroBannerText_heroBannerSettings on HeroBannerSettingsType {
            textAlignment
            contentColor
            eyeBrowText
            headerText
            dekText
            ctaStyle
        }
    `,
    linkData: graphql`
        fragment HpSharedHeroBannerText_linkData on LinkData {
            path
            ...SeoButton_linkData
        }
    `,
});
