import { graphql, readInlineData } from 'react-relay';
import {
    trackEvent,
    trackEcommerce,
    type EventNameValues,
    trackingConstants,
    eventNameConstants,
} from 'dibs-tracking';
import { type ItemClickProps } from '../../types';

const itemFragment = graphql`
    fragment recommendedItemTracking_item on Item @inline {
        serviceId
        contemporaryTrackingString
        title
        browseUrl
        categoryCode
        seller {
            serviceId
        }
    }
`;

const trackInteraction = ({ action, label }: { action: string; label: string }): void => {
    trackEvent({
        category: 'promo interaction',
        action,
        label,
        value: 0,
    });
};

const { ECOM_PRODUCT_CLICK, ECOM_PRODUCT_IMPRESSION } = trackingConstants;
const { EVENT_SELECT_ITEM, EVENT_VIEW_ITEM_LIST } = eventNameConstants;

type RecentItemsProp = {
    type: typeof ECOM_PRODUCT_CLICK | typeof ECOM_PRODUCT_IMPRESSION;
    variant: string;
    list: string;
    eventName: EventNameValues;
} & ItemClickProps;

export const trackRecentItems = ({
    type,
    item: itemRef,
    index,
    variant,
    list,
    eventName,
}: RecentItemsProp): void => {
    const item = readInlineData(itemFragment, itemRef);
    const actionField: { list: string; itemId?: string } = { list };
    if (eventName === EVENT_SELECT_ITEM && item?.serviceId) {
        actionField.itemId = item.serviceId;
    }

    trackEcommerce({
        type,
        actionField,
        eventName,
        products: [
            {
                brand: item?.seller?.serviceId,
                category: item?.browseUrl || item?.categoryCode,
                categoryCode: item?.categoryCode,
                id: item?.serviceId,
                variant,
                name: item?.title,
                list,
                dimension83: item?.contemporaryTrackingString,
                position: index,
            },
        ],
    });
};

type trackItemClickArgs = {
    variant?: string;
} & ItemClickProps;

export const trackItemClick = ({ item, index, variant = 'products' }: trackItemClickArgs): void => {
    trackInteraction({
        action: `${variant} recommended for you items item clicked`,
        label: 'none',
    });
    trackRecentItems({
        type: ECOM_PRODUCT_CLICK,
        item,
        index: index + 1, // Tracking needs non zero index position.
        variant,
        list: `${variant} recommended for you items carousel`,
        eventName: EVENT_SELECT_ITEM,
    });
};

type ItemImpressionProps = {
    variant?: string;
} & ItemClickProps;

export const trackItemImpression = ({
    item,
    index,
    variant = 'products',
}: ItemImpressionProps): void => {
    trackRecentItems({
        type: ECOM_PRODUCT_IMPRESSION,
        item,
        index: index + 1, // Tracking needs non zero index position.
        variant,
        list: `${variant} recommended for you items carousel`,
        eventName: EVENT_VIEW_ITEM_LIST,
    });
};
