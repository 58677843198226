import { Component, type ReactElement, type ReactNode, Fragment } from 'react';

import { InViewport } from 'dibs-in-viewport/exports/InViewport';

type HandleIndexChange = (args: { prev: number; index: number }) => void;
type HandlePageChange = (page: number) => void;
type HandleInitialDisplay = (args: { inViewport: boolean }) => void;
type OnItemsImpression<T> = (args: { visibleItems: T; index: number }) => void;

type Props<T> = {
    children: (args: {
        handleIndexChange: HandleIndexChange;
        handlePageChange: HandlePageChange;
    }) => ReactElement;
    itemsPerPage: number;
    items: ReadonlyArray<T>;
    onInitialDisplay?: () => void;
    onItemsImpression?: OnItemsImpression<ReadonlyArray<T>>;
};

class HpSharedCarouselTracking<T> extends Component<Props<T>> {
    trackedCarouselPages = new Set();

    trackImpressions(page: number): void {
        if (!this.trackedCarouselPages.has(page)) {
            const { items, onItemsImpression = () => {} } = this.props;
            const itemsPerPage = Math.floor(this.props.itemsPerPage);
            const startIndex = page * itemsPerPage;
            const visibleItems = (items || []).slice(startIndex, startIndex + itemsPerPage);

            onItemsImpression({
                visibleItems,
                index: startIndex,
            });

            this.trackedCarouselPages.add(page);
        }
    }

    handleInitialDisplay: HandleInitialDisplay = ({ inViewport }) => {
        const { onInitialDisplay = () => {} } = this.props;

        if (inViewport) {
            onInitialDisplay();
            this.trackImpressions(0);
        }
    };

    handleIndexChange: HandleIndexChange = ({ prev, index }) => {
        const itemsPerPage = Math.floor(this.props.itemsPerPage);
        const prevPage = Math.ceil(prev / itemsPerPage);
        const page = Math.ceil(index / itemsPerPage);

        if (page !== prevPage) {
            this.trackImpressions(page);
        }
    };

    handlePageChange: HandlePageChange = page => {
        this.trackImpressions(page);
    };

    render(): ReactNode {
        return (
            <Fragment>
                <InViewport stopWhenInViewport onInViewportChange={this.handleInitialDisplay}>
                    {this.props.children({
                        handleIndexChange: this.handleIndexChange,
                        handlePageChange: this.handlePageChange,
                    })}
                </InViewport>
            </Fragment>
        );
    }
}

export { HpSharedCarouselTracking };
