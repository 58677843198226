import { createContext, useContext, type ReactNode, type FC } from 'react';

type HomePageContextType = {
    isBot: boolean;
    isDesktop: boolean;
    isMobile: boolean;
    isTablet: boolean;
    isLoggedInUser: boolean;
    userType: string;
    isHPVideoAutoPlayVariant: boolean;
    isRemoveRDIDTestVariant: boolean;
    isNewArrivalsTestVariant?: boolean;
};

export const HomePageContext = createContext<HomePageContextType>({
    isBot: false,
    isDesktop: false,
    isMobile: false,
    isTablet: false,
    isLoggedInUser: false,
    userType: '',
    isHPVideoAutoPlayVariant: false,
    isRemoveRDIDTestVariant: false,
    isNewArrivalsTestVariant: false,
});

type ServerVarsProviderProps = {
    children: ReactNode;
} & Required<HomePageContextType>;

export const useHomePageContext = (): HomePageContextType => {
    const context = useContext(HomePageContext);
    if (!context) {
        throw new Error('useHomePageContext must be used within a HomePageContextProvider');
    }
    return context;
};

export const HomePageContextProvider: FC<ServerVarsProviderProps> = ({
    children,
    isBot,
    isDesktop,
    isMobile,
    isTablet,
    isLoggedInUser,
    userType,
    isHPVideoAutoPlayVariant,
    isRemoveRDIDTestVariant,
    isNewArrivalsTestVariant,
}) => {
    return (
        <HomePageContext.Provider
            value={{
                isBot,
                isMobile,
                isTablet,
                isDesktop,
                isLoggedInUser,
                userType,
                isHPVideoAutoPlayVariant,
                isRemoveRDIDTestVariant,
                isNewArrivalsTestVariant,
            }}
        >
            {children}
        </HomePageContext.Provider>
    );
};
