/**
 * @generated SignedSource<<631d45fa46025c6893b8cad5c0ef66d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedTopHeroBannerImageItem_bannerItem$data = {
  readonly cmsId: number | null;
  readonly cta: string | null;
  readonly imageCopy: string | null;
  readonly imageHeight?: string | null;
  readonly imageUrl?: string | null;
  readonly imageWidth?: string | null;
  readonly isFullBleedImage: boolean | null;
  readonly linkData: {
    readonly path: string | null;
  };
  readonly mobileImageHeight?: string | null;
  readonly mobileImageUrl?: string | null;
  readonly mobileImageWidth?: string | null;
  readonly " $fragmentType": "HpSharedTopHeroBannerImageItem_bannerItem";
};
export type HpSharedTopHeroBannerImageItem_bannerItem$key = {
  readonly " $data"?: HpSharedTopHeroBannerImageItem_bannerItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedTopHeroBannerImageItem_bannerItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isMobile"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedTopHeroBannerImageItem_bannerItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cmsId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageCopy",
      "storageKey": null
    },
    {
      "condition": "isMobile",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageHeight",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageWidth",
          "storageKey": null
        }
      ]
    },
    {
      "condition": "isMobile",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mobileImageHeight",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mobileImageWidth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mobileImageUrl",
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LinkData",
      "kind": "LinkedField",
      "name": "linkData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cta",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFullBleedImage",
      "storageKey": null
    }
  ],
  "type": "TopHeroBannerItem",
  "abstractKey": null
};

(node as any).hash = "9974b723a8f1762d34193f909527b17f";

export default node;
