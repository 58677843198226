import { type FC } from 'react';
import { ClientSuspense } from 'dibs-elements/exports/ClientSuspense';
import { Spinner } from 'dibs-elements/exports/Spinner';
import {
    RecommendedItemsCarousel,
    type Props,
} from '../RecommendedItemsCarousel/RecommendedItemsCarousel';
import { RecommendedItemsWrapper } from '../RecommendedItemsWrapper/RecommendedItemsWrapper';

import styles from './RecommendedItemsLazy.scss';

export const RecommendedItemsLazy: FC<Props> = props => {
    const loading = (
        <RecommendedItemsWrapper>
            <div className={styles.loading}>
                <Spinner />
            </div>
        </RecommendedItemsWrapper>
    );

    return (
        <ClientSuspense fallback={loading}>
            <RecommendedItemsCarousel {...props} />
        </ClientSuspense>
    );
};
