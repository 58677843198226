import { type FC, type MouseEvent, createRef } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

// Components
import { VisibilityTracker } from 'dibs-visibility-tracker/exports/VisibilityTracker';
import { Info } from '../Info';
import { Product } from '../Product';
import QuickViewItem from '../QuickViewItem';
import useQuickView from '../QuickViewProvider/useQuickView';
import { TileContextProvider } from '../helpers/TileContext';
import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';

// Helpers
import { type Tile_item$data as TileItemProp } from './__generated__/Tile_item.graphql';
import { type Tile_viewer$data as TileViewerProp } from './__generated__/Tile_viewer.graphql';
import { type ImageSizeType } from '../types/StyleSizes';

// Styles
import styles from './styles.scss';

type Props = {
    buyerHost?: string;
    currency?: string;
    dimensions?: string;
    disableLinks?: boolean;
    imageDraggable?: boolean; // undefined draggable will result in default browser behavior
    index?: number;
    item?: TileItemProp | null;
    itemsPerRow?: number;
    isMobile?: boolean;
    listingStatus?: string;
    showInternalSellerName?: boolean;
    showByLine?: boolean;
    showSellerName?: boolean;
    showCreatorName?: boolean;
    showQuickView?: boolean;
    showQuickViewIcon?: boolean;
    showLightBox?: boolean;
    showPrice?: boolean;
    showShippingPrequotes?: boolean;
    showInaTransactionsBadge?: boolean;
    showMeetsPricingBadge?: boolean;
    showTitle?: boolean;
    onClick?: (event: MouseEvent<HTMLElement>) => void;
    onContentLoaded?: () => void;
    useLoFiLazyLoader?: boolean;
    imageLoadVerticalOffset?: number;
    onContentVisible?: () => void;
    srcSetSizes?: string;
    srcSetWidths?: Array<number>;
    srcSetQuality?: number;
    imageSize?: ImageSizeType;
    showSkusBadge?: boolean;
    hideDivider?: boolean;
    hideProductDetails?: boolean;
    hideImagePadding?: boolean;
    hideImageSidePadding?: boolean;
    priceBold?: boolean;
    titleOneLine?: boolean;
    viewer: TileViewerProp;
};

const TileComponent: FC<Props> = props => {
    const tileRef = createRef<HTMLDivElement>();
    const {
        dimensions,
        imageDraggable,
        index,
        item,
        isMobile,
        listingStatus,
        showInternalSellerName,
        showCreatorName,
        showPrice,
        showTitle,
        onClick,
        onContentLoaded,
        imageLoadVerticalOffset,
        onContentVisible,
        srcSetSizes,
        srcSetWidths,
        srcSetQuality,
        hideDivider,
        hideProductDetails,
        hideImagePadding,
        hideImageSidePadding,
        // default values
        buyerHost = '',
        imageSize = 'imageMedium',
        currency = 'USD',
        disableLinks = false,
        itemsPerRow = 3,
        showByLine = true,
        showSellerName = true,
        showQuickView = true,
        showQuickViewIcon = true,
        showLightBox = false,
        showShippingPrequotes,
        showInaTransactionsBadge,
        showMeetsPricingBadge,
        useLoFiLazyLoader = false,
        showSkusBadge = false,
        priceBold = false,
        titleOneLine = false,
        viewer = null,
    } = props;

    const { activeQuickView, openQuickView } = useQuickView();

    if (!item) {
        return null;
    }

    const seller = item.seller;
    const dealerPk = seller?.serviceId;
    const isQuickViewActive = index === activeQuickView;
    const isMultiSkuFurniture = item.isMultiSku && item.vertical === 'furniture';
    const displaySkusBadge = showSkusBadge ? isMultiSkuFurniture : false;

    const sharedProps = {
        item,
        title: item.title || '',
        pdpUrl: buyerHost + (item.localizedPdpUrl || ''),
        onClick,
        disableLinks,
    };

    const srcSetProps = {
        srcSetSizes,
        srcSetWidths,
        srcSetQuality,
    };

    const openQuickViewFunc = (): void => {
        if (typeof index === 'number') {
            openQuickView?.(index);
        }
    };

    return (
        <TileContextProvider imageSize={imageSize}>
            <div
                className={styles.tile}
                data-pk={item.serviceId}
                data-dealerpk={dealerPk}
                data-vertical={item.vertical}
                ref={tileRef}
                data-tn="item-title"
            >
                {onContentVisible && (
                    <VisibilityTracker
                        elementRef={tileRef}
                        onVisibilityChange={({ isVisible }) => {
                            if (isVisible) {
                                onContentVisible();
                            }
                        }}
                    />
                )}
                <Product
                    {...sharedProps}
                    dimensions={dimensions}
                    index={index}
                    imageDraggable={imageDraggable}
                    itemsPerRow={itemsPerRow}
                    onContentLoaded={onContentLoaded}
                    openQuickView={openQuickViewFunc}
                    showQuickView={showQuickView}
                    showLightBox={showLightBox}
                    useLoFiLazyLoader={useLoFiLazyLoader}
                    imageLoadVerticalOffset={imageLoadVerticalOffset}
                    showQuickViewIcon={showQuickViewIcon}
                    hideDivider={hideDivider}
                    hideProductDetails={hideProductDetails}
                    hideImagePadding={hideImagePadding}
                    hideImageSidePadding={hideImageSidePadding}
                    {...srcSetProps}
                />
                <div className={styles.info}>
                    <HeadingLevel>
                        {Heading => (
                            <Info
                                {...sharedProps}
                                currency={currency}
                                showPrice={showPrice}
                                seller={seller || null}
                                showInternalSellerName={showInternalSellerName}
                                listingStatus={listingStatus}
                                showByLine={showByLine}
                                showSellerName={showSellerName}
                                showCreatorName={showCreatorName}
                                showTitle={showTitle}
                                showSkusBadge={displaySkusBadge}
                                priceBold={priceBold}
                                titleOneLine={titleOneLine}
                                viewer={viewer}
                                Heading={Heading}
                                showShippingPrequotes={showShippingPrequotes}
                                showInaTransactionsBadge={showInaTransactionsBadge}
                                showMeetsPricingBadge={showMeetsPricingBadge}
                            />
                        )}
                    </HeadingLevel>
                </div>
                {showQuickView && showQuickViewIcon && (
                    <QuickViewItem
                        item={item}
                        itemSearch={null}
                        isMobile={isMobile}
                        isQuickViewActive={isQuickViewActive}
                    />
                )}
            </div>
        </TileContextProvider>
    );
};

export const Tile = createFragmentContainer(TileComponent, {
    viewer: graphql`
        fragment Tile_viewer on Viewer
        @argumentDefinitions(
            fetchRegionalInfo: { type: "Boolean", defaultValue: false }
            userZipCode: { type: "String", defaultValue: "" }
            userCountryCode: { type: "String", defaultValue: "" }
        ) {
            ...Info_viewer
                @arguments(
                    fetchRegionalInfo: $fetchRegionalInfo
                    userZipCode: $userZipCode
                    userCountryCode: $userCountryCode
                )
        }
    `,
    item: graphql`
        fragment Tile_item on Item
        @argumentDefinitions(
            isTrade: { type: "Boolean", defaultValue: false }
            showSeller: { type: "Boolean!" }
            showPrice: { type: "Boolean", defaultValue: true }
            pageDisplayEnum: { type: "PageDisplayEnum", defaultValue: searchAndBrowse }
            isInternalAdmin: { type: "Boolean", defaultValue: false }
        ) {
            isMultiSku
            serviceId
            localizedPdpUrl
            title
            vertical
            ...Info_item
                @arguments(
                    isTrade: $isTrade
                    showPrice: $showPrice
                    pageDisplayEnum: $pageDisplayEnum
                    isInternalAdmin: $isInternalAdmin
                )
            ...Product_item
            ...QuickViewItem_item
            seller @include(if: $showSeller) {
                serviceId
                ...Info_seller
            }
        }
    `,
});
