/**
 * @generated SignedSource<<2cf72411cf1a043110dddec563611198>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedRecommendedItems_componentModule$data = {
  readonly cmsDisplayTitle: string | null;
  readonly " $fragmentType": "HpSharedRecommendedItems_componentModule";
};
export type HpSharedRecommendedItems_componentModule$key = {
  readonly " $data"?: HpSharedRecommendedItems_componentModule$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedRecommendedItems_componentModule">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedRecommendedItems_componentModule",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cmsDisplayTitle",
      "storageKey": null
    }
  ],
  "type": "RecommendedItemsModule",
  "abstractKey": null
};

(node as any).hash = "c34be5eb5a5c32074ad7f1415cbde611";

export default node;
