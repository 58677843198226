import { useRef } from 'react';
import PropTypes from 'prop-types';
import { graphql, useFragment } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';
import Tile from 'dibs-search-product-tile';

import {
    trackCategoryClick,
    trackItemImpression,
    trackItemClick,
} from '../helpers/newArrivalsTracking';

import styles from './HpSharedCategoryProducts.scss';

export const HpSharedCategoryProducts = props => {
    const {
        title,
        products,
        shopNowUrl,
        imageSize,
        srcSetSizes,
        categoryIndex,
        personalizationModule: personalizationModuleRef,
    } = props;

    const trackedImpressionsRef = useRef(new Set());

    const personalizationModule = useFragment(
        graphql`
            fragment HpSharedCategoryProducts_personalizationModule on PersonalizationModule {
                ...newArrivalsTracking_personalizationModule
            }
        `,
        personalizationModuleRef
    );

    const getItemIndex = index => categoryIndex * (products || []).length + index;

    return (
        <>
            <div className={styles.category}>
                <div data-tn="personalized-category-title" className={styles.title}>
                    {title}
                </div>
                <Link
                    dataTn="personalized-category-cta"
                    className={styles.shopNow}
                    href={shopNowUrl}
                    onClick={() => {
                        trackCategoryClick({
                            personalizationModule: personalizationModule || null,
                        });
                    }}
                >
                    <FormattedMessage
                        id="homepage.modules.categoryProducts.viewAll"
                        defaultMessage="View All"
                    />
                </Link>
            </div>
            <div className={styles.products}>
                <div className="rowFlex">
                    {(products || []).map((item, index) => (
                        <div className={`colXs6 ${styles.productTile}`} key={item.localizedPdpUrl}>
                            <Tile
                                item={item}
                                showLightBox
                                index={index}
                                itemsPerRow={2}
                                imageSize={imageSize}
                                srcSetSizes={srcSetSizes}
                                useLoFiLazyLoader
                                showQuickView={false}
                                showSellerName={false}
                                showPrice={false}
                                hideProductDetails
                                showByLine={false}
                                onContentVisible={() => {
                                    const itemIndex = getItemIndex(index);

                                    if (!trackedImpressionsRef.current.has(itemIndex)) {
                                        trackedImpressionsRef.current.add(itemIndex);

                                        trackItemImpression({
                                            item,
                                            itemIndex,
                                        });
                                    }
                                }}
                                onClick={() => {
                                    trackItemClick({
                                        item,
                                        itemIndex: getItemIndex(index),
                                    });
                                }}
                                viewer={null}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

HpSharedCategoryProducts.propTypes = {
    title: PropTypes.string,
    shopNowUrl: PropTypes.string,
    products: PropTypes.array,
    imageSize: PropTypes.string,
    srcSetSizes: PropTypes.string,
    categoryIndex: PropTypes.number,
    personalizationModule: PropTypes.object,
};
