/**
 * @generated SignedSource<<4479008d38ad27bc6427754fc87ce54d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedHeroBannerText_heroBannerSettings$data = {
  readonly contentColor: string | null;
  readonly ctaStyle: string | null;
  readonly dekText: string | null;
  readonly eyeBrowText: string | null;
  readonly headerText: string | null;
  readonly textAlignment: string | null;
  readonly " $fragmentType": "HpSharedHeroBannerText_heroBannerSettings";
};
export type HpSharedHeroBannerText_heroBannerSettings$key = {
  readonly " $data"?: HpSharedHeroBannerText_heroBannerSettings$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedHeroBannerText_heroBannerSettings">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedHeroBannerText_heroBannerSettings",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "textAlignment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eyeBrowText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "headerText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dekText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctaStyle",
      "storageKey": null
    }
  ],
  "type": "HeroBannerSettingsType",
  "abstractKey": null
};

(node as any).hash = "ab74940a4a9f5d69ef171325c258cece";

export default node;
