import { lazy, type ComponentType, type ComponentProps, type FC } from 'react';

/**
 * `dibsLazyClient` should be used in combination with the `ClientSuspense` helper.
 *
 * Example:
 * ```javascript
 * import { ClientSuspense } from 'dibs-elements/exports/ClientSuspense';
 * import { dibsLazyClient } from 'dibs-lazy/exports/dibsLazyClient';
 * const DibsLazyClient = dibsLazyClient(() => import('./Component'));
 *
 * <ClientSuspense fallback={<>Loading...</>}>
 *    <DibsLazyClient />
 * </ClientSuspense>
 * ```
 * @param factory function that returns a dynamic import with default export
 * @returns lazy component that should be accessed only on the client side
 */
export function dibsLazyClient<T extends ComponentType<$TSFixMe>>(
    factory: () => Promise<{ default: T }>
): FC<ComponentProps<T>> {
    /**
     * This is just a wrapper which would allow switching between dibsLazyClient and development_only_dibsLazyClient.
     * development_only_dibsLazyClient still has unclear behavior and should be used with caution. Until official React
     * documentation is released, we should use dibsLazyClient.
     */
    const Component = lazy(factory);

    const DibsLazyClient: FC<ComponentProps<T>> = props => {
        return <Component {...props} />;
    };

    return DibsLazyClient;
}
