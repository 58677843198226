import { useState, type FC, useRef, type ReactNode } from 'react';
import classNames from 'classnames';
import LazyImage from 'dibs-react-lazy-image';
import styles from './HpLazyImageWrapper.scss';

type Props = {
    className?: string;
    imageHeight?: number | string | null;
    imageWidth?: number | string | null;
    useLazyLoad?: boolean;
    onImageLoaded?: () => void;
    children: ReactNode;
};

const convertStringToNumber = (value: number | string | null): number => {
    return parseInt(value?.toString() || '', 10);
};

const HpLazyImageWrapper: FC<Props> = ({
    children,
    className,
    imageHeight = 0,
    imageWidth = 0,
    useLazyLoad = true,
    onImageLoaded = () => {},
}) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [showShimmer, setShowShimmer] = useState(false);

    const aspectRatio = convertStringToNumber(imageHeight) / convertStringToNumber(imageWidth);

    return (
        <div
            ref={wrapperRef}
            className={classNames(styles.container, className)}
            /**
             * When expressed in percentage, paddingTop, is calculated based on the container width.
             * (unlike height which, obviously, is calculated based on parent container height).
             * This little trick lets us render a correct responsive image placeholder on the server.
             */
            style={{ paddingTop: aspectRatio ? aspectRatio * 100 + '%' : 'unset' }}
        >
            {useLazyLoad ? (
                <>
                    <LazyImage
                        visibilityRef={wrapperRef}
                        offsetVertical={300}
                        offsetHorizontal={800}
                        placeholderClass={classNames(
                            styles.lazyPlaceholder,
                            showShimmer ? styles.shimmer : ''
                        )}
                        className={className}
                        onContentVisible={() => setShowShimmer(true)}
                        onImageLoaded={() => {
                            setShowShimmer(false);
                            onImageLoaded();
                        }}
                        placeholder={' '}
                    >
                        {children}
                    </LazyImage>
                    <noscript>{children}</noscript>
                </>
            ) : (
                children
            )}
        </div>
    );
};

export default HpLazyImageWrapper;
