import { type FC, type MouseEventHandler, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'dibs-react-intl';
import { handleLocaleUrl } from 'dibs-intl/exports/urls';
import CheckCircle from 'dibs-icons/exports/CheckCircle';
import { SeoLink } from 'dibs-elements/exports/SeoLink';
import { trackEvent } from 'dibs-tracking';
import classnames from 'classnames';
import { useHomePageContext } from '../HomePageContext';
import { useBuyerIncentivesMessages } from 'dibs-buyer-incentives/exports/useBuyerIncentivesMessages';

import dibsCss from 'dibs-css';
import styles from './HpSharedSkinnyBannerModule.scss';

const HpSharedSkinnyBannerModule: FC = () => {
    const { isMobile } = useHomePageContext();
    const intl = useIntl();
    const buyerIncentives = useBuyerIncentivesMessages({ useAll: true });

    const handleClick: MouseEventHandler = useCallback(event => {
        trackEvent(
            {
                category: 'promo interaction',
                action: 'Learn More Click',
                label: '[homepage]',
            },
            event
        );
    }, []);

    return (
        <div
            className={classnames(
                styles.sectionWrapper,
                dibsCss.textCenter,
                dibsCss.bgShagreen300,
                {
                    [dibsCss.mtLarger]: !isMobile,
                    [dibsCss.pXlarge]: !isMobile,

                    [dibsCss.mtLarge]: isMobile,
                    [dibsCss.ptMedium]: isMobile,
                    [dibsCss.pbSmall]: isMobile,
                    [dibsCss.pxSmall]: isMobile,
                }
            )}
        >
            <div
                className={classnames(dibsCss.mbXxsmall, {
                    [dibsCss.sassyFontHeaderSizeVinDiesel]: !isMobile,
                    [dibsCss.sassyFontHeaderSizeXLarge]: isMobile,
                })}
                data-tn="skinny-banner-title"
            >
                <FormattedMessage
                    id="HpSharedSkinnyBannerModule.title"
                    defaultMessage="The 1stDibs Promise"
                />
            </div>
            <div
                className={classnames(dibsCss.sassyFontHeaderSizeMedium, dibsCss.mbMedium, {
                    [dibsCss.sassyFontHeaderSizeMedium]: !isMobile,
                    [dibsCss.mbMedium]: !isMobile,
                    [dibsCss.sassyFontHeaderSizeSmall]: isMobile,
                    [dibsCss.mbSmall]: isMobile,
                })}
            >
                <FormattedMessage
                    id="HpSharedSkinnyBannerModule.subTitle"
                    defaultMessage="We’re committed to your satisfaction and peace of mind with every purchase"
                />
            </div>
            <div
                className={classnames(styles.incentivesWrapper, dibsCss.grid, dibsCss.mbSmall, {
                    [dibsCss.grid]: !isMobile,
                    [dibsCss.gapSmall]: !isMobile,
                    [dibsCss.inlineGrid]: isMobile,
                    [dibsCss.gapXsmall]: isMobile,
                })}
            >
                {buyerIncentives.map((copy, index) => (
                    <div
                        key={index}
                        className={classnames(dibsCss.flex, {
                            [dibsCss.sassyFontHeaderSizeLarge]: !isMobile,
                            [dibsCss.justifyCenter]: !isMobile,

                            [dibsCss.sassyFontHeaderSizeSmall]: isMobile,
                            [dibsCss.gapXsmall]: isMobile,
                            [dibsCss.itemsCenter]: isMobile,
                        })}
                    >
                        {isMobile && (
                            <span className={dibsCss.fillShagreen700}>
                                <CheckCircle variation="20Filled" />
                            </span>
                        )}
                        {copy}
                    </div>
                ))}
            </div>
            <div className={dibsCss.block}>
                <SeoLink
                    className={classnames({
                        [dibsCss.sassyFontBodyLarge]: !isMobile,

                        [dibsCss.sassyFontBodyMedium]: isMobile,
                        [dibsCss.inlineBlock]: isMobile,
                    })}
                    dataTn="skinny-banner-link"
                    linkData={{
                        path: handleLocaleUrl('/about/promise/', intl.locale),
                        isLinkable: true,
                    }}
                    onClick={handleClick}
                    target="_blank"
                >
                    <FormattedMessage
                        id="HpSharedSkinnyBannerModule.learnMore"
                        defaultMessage="Learn More"
                    />
                </SeoLink>
            </div>
        </div>
    );
};

export default HpSharedSkinnyBannerModule;
