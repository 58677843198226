import { type FC, useEffect, useMemo } from 'react';
import { graphql, useRefetchableFragment } from 'react-relay';
import { ScrollCarousel } from 'dibs-scroll-carousel/exports/ScrollCarousel';
import { getLocalHistoryItemIds } from 'dibs-recent-history/exports/getLocalHistoryItemIds';
import { VisibilityTracker } from 'dibs-visibility-tracker/exports/VisibilityTracker';
import { RecommendedItemsWrapper } from '../RecommendedItemsWrapper/RecommendedItemsWrapper';
import { RecommendedItem } from '../RecommendedItem/RecommendedItem';
import { type ItemClickProps, type ItemContentVisibleProps, type OnFavorited } from '../types';

import { type RecommendedItemsCarousel_viewer$key } from './__generated__/RecommendedItemsCarousel_viewer.graphql';
import { type RecommendedItemsCarouselViewerRefetchQuery } from './__generated__/RecommendedItemsCarouselViewerRefetchQuery.graphql';

export type Props = {
    viewer: RecommendedItemsCarousel_viewer$key;
    isMobile: boolean;
    userId: string;
    itemsPerPage?: number;
    titleClassName?: string;
    showTitle?: boolean;
    onItemClick?: (props: ItemClickProps) => void;
    onItemContentVisible?: (props: ItemContentVisibleProps) => void;
    onCarouselContentVisible?: ({ itemsReturned }: { itemsReturned: number }) => void;
    onArrowClick?: ({ direction }: { direction: string }) => void;
    onFavorited?: OnFavorited;
    onUnFavorited?: ({ itemId }: { itemId: string | null }) => void;
};

const viewerFragment = graphql`
    fragment RecommendedItemsCarousel_viewer on Viewer
    @refetchable(queryName: "RecommendedItemsCarouselViewerRefetchQuery")
    @argumentDefinitions(
        recentlyViewedIds: { type: "[String]", defaultValue: [] }
        userIds: { type: "[String]", defaultValue: [] }
        loadPortfolioData: { type: "Boolean", defaultValue: false }
        returnAmount: { type: "Int", defaultValue: 15 }
        pageSize: { type: "Int", defaultValue: 18 }
    ) {
        recommendedItems(
            recentlyViewedIds: $recentlyViewedIds
            userIds: $userIds
            pageSize: $pageSize
            returnAmount: $returnAmount
        ) {
            serviceId
            ...RecommendedItem_item
        }
        ...RecommendedItem_viewer
            @arguments(userIds: $userIds, loadPortfolioData: $loadPortfolioData)
    }
`;

const MOBILE_ITEMS_PER_PAGE = 2.5;

export const RecommendedItemsCarousel: FC<Props> = ({
    viewer: viewerRef,
    itemsPerPage = 5,
    isMobile,
    userId,
    showTitle,
    onItemClick,
    onItemContentVisible,
    onCarouselContentVisible = () => {},
    onArrowClick = () => {},
    onFavorited,
    onUnFavorited,
}) => {
    const [viewer, refetch] = useRefetchableFragment<
        RecommendedItemsCarouselViewerRefetchQuery,
        RecommendedItemsCarousel_viewer$key
    >(viewerFragment, viewerRef);
    const items = viewer.recommendedItems || [];
    const itemsIds = useMemo(() => items.map(item => item?.serviceId), [items]);
    const itemsToShow = isMobile ? MOBILE_ITEMS_PER_PAGE : itemsPerPage;

    useEffect(() => {
        if (userId) {
            const localHistoryItemIds = getLocalHistoryItemIds({ isClient: true });
            refetch({
                recentlyViewedIds: localHistoryItemIds,
                userIds: [userId],
                loadPortfolioData: true,
            });
        }
    }, [refetch, userId]);

    if (!items.length) {
        return null;
    }

    return (
        <VisibilityTracker
            onVisibilityChange={() => onCarouselContentVisible({ itemsReturned: items.length })}
        >
            <RecommendedItemsWrapper isMobile={isMobile}>
                <ScrollCarousel
                    animation="slide"
                    enableScroll
                    itemsToShow={itemsToShow}
                    dataTn="recommended-items-carousel"
                    {...(isMobile
                        ? {
                              stepSize: 1,
                              gapSize: 'small',
                              scrollPadding: 'small',
                          }
                        : {
                              stepSize: itemsPerPage,
                              isInfinite: true,
                              showArrows: true,
                              onArrowClick: onArrowClick,
                          })}
                >
                    {items.map((item, index) => (
                        <RecommendedItem
                            key={index}
                            viewer={viewer}
                            item={item}
                            itemsIds={itemsIds}
                            userId={userId}
                            index={index}
                            isMobile={isMobile}
                            itemsPerPage={itemsPerPage}
                            showTitle={showTitle}
                            onItemClick={onItemClick}
                            onItemContentVisible={onItemContentVisible}
                            onFavorited={onFavorited}
                            onUnFavorited={onUnFavorited}
                        />
                    ))}
                </ScrollCarousel>
            </RecommendedItemsWrapper>
        </VisibilityTracker>
    );
};
