/**
 * @generated SignedSource<<eca11d801fe76caee99e1e696abc5d75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedNewArrivals_personalization$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly categoryItems: ReadonlyArray<{
        readonly serviceId: string | null;
      } | null> | null;
      readonly " $fragmentSpreads": FragmentRefs<"HpSharedNewArrivalsModule_personalizationModule">;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "HpSharedNewArrivals_personalization";
};
export type HpSharedNewArrivals_personalization$key = {
  readonly " $data"?: HpSharedNewArrivals_personalization$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedNewArrivals_personalization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedNewArrivals_personalization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PersonalizationModuleEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PersonalizationModule",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Item",
              "kind": "LinkedField",
              "name": "categoryItems",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "serviceId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "HpSharedNewArrivalsModule_personalizationModule"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PersonalizationModuleConnection",
  "abstractKey": null
};

(node as any).hash = "e0777572d9ee2c0c69e41309aaa65695";

export default node;
