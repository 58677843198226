import { useFragment, graphql } from 'react-relay';
import { getAmountInCurrency } from '../helpers/getAmountInCurrency';

import { type ConvertedAmount } from './types/ConvertedAmount';
import { type useAuctionPricing_item$key as Item } from './__generated__/useAuctionPricing_item.graphql';

const itemFragment = graphql`
    fragment useAuctionPricing_item on Item
    @argumentDefinitions(page: { type: "PageDisplayEnum" }) {
        relevantAuctionSolution(page: $page) {
            hasReserve
            reserveMet
            bids {
                totalResults
            }
            highestBidAmount {
                convertedAmountList {
                    amount
                    currency
                }
            }
        }
        relevantAuctionLot(page: $page) {
            buyItNowPrice {
                convertedAmountList {
                    amount
                    currency
                }
            }
            openingBid {
                convertedAmountList {
                    amount
                    currency
                }
            }
            reservePrice {
                convertedAmountList {
                    amount
                    currency
                }
            }
        }
    }
`;

export type ReturnType = {
    totalBids: number;
    reserveMet: boolean;
    hasReserve: boolean;
    isBuyItNowEligible: boolean;
    buyItNowAmount: ConvertedAmount;
    startingBidAmount: ConvertedAmount;
    currentBidAmount: ConvertedAmount;
    reservePriceAmount: ConvertedAmount;
};

export const useAuctionPricing = ({
    currency,
    item: itemRef,
}: {
    item: Item | null | undefined;
    currency: string;
}): ReturnType => {
    const item = useFragment(itemFragment, itemRef);
    const { relevantAuctionSolution, relevantAuctionLot } = item || {};
    const { reserveMet, bids, highestBidAmount, hasReserve } = relevantAuctionSolution || {};
    const totalBids = bids?.totalResults || 0;
    const isBuyItNowEligible = totalBids === 0;
    const { buyItNowPrice, openingBid, reservePrice } = relevantAuctionLot || {};

    return {
        totalBids,
        isBuyItNowEligible,
        reserveMet: !!reserveMet,
        hasReserve: !!hasReserve,
        buyItNowAmount: getAmountInCurrency(buyItNowPrice?.convertedAmountList || [], currency),
        startingBidAmount: getAmountInCurrency(openingBid?.convertedAmountList || [], currency),
        currentBidAmount: getAmountInCurrency(
            highestBidAmount?.convertedAmountList || [],
            currency
        ),
        reservePriceAmount: getAmountInCurrency(reservePrice?.convertedAmountList || [], currency),
    };
};
