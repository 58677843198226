/**
 * @generated SignedSource<<afc7ac4f49df2ae280cb794c293fbc2b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedLocationsModule_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedBestsellingDesignsCarousel_viewer" | "HpSharedExcellentValuesCarousel_viewer" | "HpSharedMostSavedItemsCarousel_viewer">;
  readonly " $fragmentType": "HpSharedLocationsModule_viewer";
};
export type HpSharedLocationsModule_viewer$key = {
  readonly " $data"?: HpSharedLocationsModule_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedLocationsModule_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isClient"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedLocationsModule_viewer",
  "selections": [
    {
      "condition": "isClient",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HpSharedMostSavedItemsCarousel_viewer"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HpSharedExcellentValuesCarousel_viewer"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HpSharedBestsellingDesignsCarousel_viewer"
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "c3657f8aed1c52838e46e74a19838295";

export default node;
