/**
 * @generated SignedSource<<7bc8a9988ab4bcfa6a1678e4717d93c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedPersonalizedCategories_personalization$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly categoryItems: ReadonlyArray<{
        readonly localizedPdpUrl: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"Tile_item" | "newArrivalsTracking_item">;
      } | null> | null;
      readonly categoryTitle: string | null;
      readonly linkData: {
        readonly path: string | null;
      };
      readonly " $fragmentSpreads": FragmentRefs<"HpSharedCategoryProducts_personalizationModule">;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "HpSharedPersonalizedCategories_personalization";
};
export type HpSharedPersonalizedCategories_personalization$key = {
  readonly " $data"?: HpSharedPersonalizedCategories_personalization$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedPersonalizedCategories_personalization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedPersonalizedCategories_personalization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PersonalizationModuleEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PersonalizationModule",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LinkData",
              "kind": "LinkedField",
              "name": "linkData",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "path",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "categoryTitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Item",
              "kind": "LinkedField",
              "name": "categoryItems",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "localizedPdpUrl",
                  "storageKey": null
                },
                {
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "showPrice",
                      "value": false
                    },
                    {
                      "kind": "Literal",
                      "name": "showSeller",
                      "value": false
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "Tile_item"
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "newArrivalsTracking_item",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "browseUrl",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "categoryCode",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "ecommerceTrackingParams",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "serviceId",
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "HpSharedCategoryProducts_personalizationModule"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PersonalizationModuleConnection",
  "abstractKey": null
};

(node as any).hash = "7aa40e2f59b12af03dffb6833c71cf2b";

export default node;
