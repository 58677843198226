/**
 * @generated SignedSource<<4caa6a84ccc8ca2df485cb6760f7bc04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type newArrivalsTracking_personalizationModule$data = {
  readonly categoryTitle: string | null;
  readonly " $fragmentType": "newArrivalsTracking_personalizationModule";
};
export type newArrivalsTracking_personalizationModule$key = {
  readonly " $data"?: newArrivalsTracking_personalizationModule$data;
  readonly " $fragmentSpreads": FragmentRefs<"newArrivalsTracking_personalizationModule">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "newArrivalsTracking_personalizationModule"
};

(node as any).hash = "0f77ed7c12d074c362fb70ff026ca844";

export default node;
