import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { GridRow } from 'dibs-elements/exports/Grid';
import { PageLayout } from 'dibs-elements/exports/PageLayout';
import { InViewport } from 'dibs-in-viewport/exports/InViewport';

import { HpSharedModuleContainer } from '../HpSharedModuleContainer/HpSharedModuleContainer';
import HpSharedShopByModuleItem from './HpSharedShopByModuleItem';
import { trackShopByItemsImpressions, trackShopByItemClick } from '../helpers/shopByTracking';
import { trackModuleLocation, trackModule } from '../helpers/moduleLocationTracking';
import { HpSharedHeader } from '../HpSharedHeader/HpSharedHeader';

export const handleInitialDisplay = ({ inViewport, items }) => {
    if (items && inViewport) {
        trackShopByItemsImpressions(items);
    }
};

export const HpSharedShopByModuleComponent = ({
    componentModule,
    useLazyLoadImages,
    moduleIndex,
    totalModules,
}) => {
    if (!componentModule) {
        return null;
    }

    const { items = [], title, cmsDisplayTitle } = componentModule;

    return (
        <HpSharedModuleContainer addTopGap>
            <PageLayout setViewportBackground="sassyColorWhite">
                <HpSharedHeader title={title} dataTn="shop-by" />
                <InViewport
                    stopWhenInViewport
                    onInViewportChange={({ inViewport }) =>
                        handleInitialDisplay({ inViewport, items })
                    }
                >
                    <GridRow>
                        {items.map((item, index) => {
                            return (
                                <HpSharedShopByModuleItem
                                    key={`${item?.linkData?.path}-${index}`}
                                    item={item}
                                    onClick={() => {
                                        trackShopByItemClick(item, index);
                                        trackModuleLocation({
                                            label: 'eight block module',
                                            moduleIndex,
                                            totalModules,
                                        });
                                        trackModule(cmsDisplayTitle);
                                    }}
                                    useLazyLoadImages={useLazyLoadImages}
                                />
                            );
                        })}
                    </GridRow>
                </InViewport>
            </PageLayout>
        </HpSharedModuleContainer>
    );
};

HpSharedShopByModuleComponent.propTypes = {
    componentModule: PropTypes.object,
    useLazyLoadImages: PropTypes.bool,
    moduleIndex: PropTypes.number,
    totalModules: PropTypes.number,
};

export const HpSharedShopByModule = createFragmentContainer(HpSharedShopByModuleComponent, {
    componentModule: graphql`
        fragment HpSharedShopByModule_componentModule on ShopByModule {
            title
            cmsDisplayTitle
            items {
                cmsId
                linkData {
                    # shopByTracking.js
                    path
                }
                ...HpSharedShopByModuleItem_item
            }
        }
    `,
});
