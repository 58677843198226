import { type FC, useRef } from 'react';
import { useFragment, graphql, useRelayEnvironment } from 'react-relay';
import classnames from 'classnames';

import dibsCss from 'dibs-css';
import { FormattedMessage } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';
import { ScrollCarousel } from 'dibs-scroll-carousel/exports/ScrollCarousel';
import { ItemTilesCarouselShimmer } from 'dibs-product-tile-carousel/exports/ItemTilesCarouselShimmer';
import { VisibilityTracker } from 'dibs-visibility-tracker/exports/VisibilityTracker';

import SharedFavoritesProvider from '../../sharedComponents/SharedFavoritesProvider';
import { useHomePageContext } from '../HomePageContext';
import HpSharedNewArrivalsModule from './HpSharedNewArrivalsModule';
import { trackModuleImpression, trackViewMoreClick } from '../helpers/newArrivalsTracking';

import styles from './HpSharedNewArrivals.module.css';

import { type HpSharedNewArrivals_viewer$key } from './__generated__/HpSharedNewArrivals_viewer.graphql';
import { type HpSharedNewArrivals_user$key } from './__generated__/HpSharedNewArrivals_user.graphql';
import { type HpSharedNewArrivals_personalization$key } from './__generated__/HpSharedNewArrivals_personalization.graphql';

const viewerFragment = graphql`
    fragment HpSharedNewArrivals_viewer on Viewer {
        ...SharedFavoritesProvider_viewer
        ...HpSharedNewArrivalsModule_viewer
    }
`;

const userFragment = graphql`
    fragment HpSharedNewArrivals_user on User {
        serviceId
    }
`;

const personalizationFragment = graphql`
    fragment HpSharedNewArrivals_personalization on PersonalizationModuleConnection {
        edges {
            node {
                categoryItems {
                    serviceId
                }
                ...HpSharedNewArrivalsModule_personalizationModule
            }
        }
    }
`;

const MODULES_TO_SHOW_RESP = 3;
const MODULES_TO_SHOW_MOBILE = 1.2;

type Props = {
    viewer: HpSharedNewArrivals_viewer$key;
    user: HpSharedNewArrivals_user$key | null;
    personalization: HpSharedNewArrivals_personalization$key | null;
    fetchFavorites: boolean;
};

const HpSharedNewArrivals: FC<Props> = ({
    viewer: viewerRef,
    user: userRef,
    personalization: personalizationRef,
    fetchFavorites,
}) => {
    const { isMobile } = useHomePageContext();
    const environment = useRelayEnvironment();

    const arrivalsRef = useRef(null);

    const viewer = useFragment(viewerFragment, viewerRef);
    const user = useFragment(userFragment, userRef);
    const personalization = useFragment(personalizationFragment, personalizationRef);

    const personalizationModules = personalization?.edges || [];
    const itemIds = personalizationModules.reduce((acc: string[], edge) => {
        const { categoryItems } = edge?.node || {};

        (categoryItems || []).forEach(categoryItem => {
            const { serviceId } = categoryItem || {};

            if (serviceId) {
                acc.push(serviceId);
            }
        });

        return acc;
    }, []);

    return (
        <div
            className={classnames(dibsCss.bgBuyertertiary, dibsCss.wScreen, styles.container, {
                [dibsCss.pLarger]: !isMobile,
                [dibsCss.pyLarge]: isMobile,
            })}
        >
            <div className={classnames(dibsCss.maxW1440px, dibsCss.my0, dibsCss.mxAuto)}>
                <div
                    className={classnames(dibsCss.textCenter, {
                        [dibsCss.sassyFontHeaderSizeXLarge]: !isMobile,
                        [dibsCss.sassyFontHeaderSizeLarge]: isMobile,
                    })}
                >
                    <FormattedMessage
                        id="homepage.modules.newArrivals.title"
                        defaultMessage="New Arrivals <subtitle>Selected For You</subtitle>"
                        values={{
                            subtitle: content => (
                                <span
                                    className={classnames({
                                        [dibsCss.sassyFontHeaderSizeXLargeTypeItalic]: !isMobile,
                                        [dibsCss.sassyFontHeaderSizeLargeTypeItalic]: isMobile,
                                    })}
                                >
                                    {content}
                                </span>
                            ),
                        }}
                    />
                </div>
                <div
                    className={classnames(dibsCss.textCenter, dibsCss.sassyFontBodyMedium, {
                        [dibsCss.mtXxsmall]: !isMobile,
                        [dibsCss.mbLarge]: !isMobile,
                        [dibsCss.mbMedium]: isMobile,
                    })}
                >
                    <Link href="/new-arrivals/?sort=recommended" onClick={trackViewMoreClick}>
                        <FormattedMessage
                            id="homepage.modules.newArrivals.viewMore"
                            defaultMessage="View More"
                        />
                    </Link>
                </div>
                {personalizationModules.length ? (
                    <SharedFavoritesProvider
                        viewer={viewer}
                        userId={user?.serviceId}
                        itemIds={itemIds}
                        fetchFavorites={fetchFavorites}
                        environment={environment}
                        fetchFolder={false}
                    >
                        {({ favorites }: $TSFixMe) => (
                            <>
                                <VisibilityTracker
                                    elementRef={arrivalsRef}
                                    onVisibilityChange={({ isVisible }) => {
                                        if (isVisible) {
                                            trackModuleImpression();
                                        }
                                    }}
                                />
                                <div
                                    ref={arrivalsRef}
                                    className={isMobile ? 'primary-viewport-width' : undefined}
                                >
                                    <ScrollCarousel
                                        enableScroll={isMobile}
                                        itemsToShow={
                                            isMobile ? MODULES_TO_SHOW_MOBILE : MODULES_TO_SHOW_RESP
                                        }
                                        scrollPadding={isMobile ? 'small' : 'none'}
                                        gapSize="small"
                                    >
                                        {personalizationModules.map(
                                            (personalizationModule, index) => (
                                                <HpSharedNewArrivalsModule
                                                    key={index}
                                                    viewer={viewer}
                                                    personalizationModule={
                                                        personalizationModule?.node || null
                                                    }
                                                    moduleIndex={index}
                                                    favorites={favorites}
                                                />
                                            )
                                        )}
                                    </ScrollCarousel>
                                </div>
                            </>
                        )}
                    </SharedFavoritesProvider>
                ) : (
                    <ItemTilesCarouselShimmer
                        itemsToShow={isMobile ? MODULES_TO_SHOW_MOBILE : MODULES_TO_SHOW_RESP}
                        height={isMobile ? '358px' : '413px'}
                        gapSize="small"
                        fullWidth={isMobile}
                    />
                )}
            </div>
        </div>
    );
};

export default HpSharedNewArrivals;
