/**
 * @generated SignedSource<<fc40494fb98b62c8342a5c827eb78f72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedLocationsModule_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedBestsellingDesignsCarousel_user" | "HpSharedExcellentValuesCarousel_user" | "HpSharedMostSavedItemsCarousel_user">;
  readonly " $fragmentType": "HpSharedLocationsModule_user";
};
export type HpSharedLocationsModule_user$key = {
  readonly " $data"?: HpSharedLocationsModule_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedLocationsModule_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isClient"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedLocationsModule_user",
  "selections": [
    {
      "condition": "isClient",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HpSharedMostSavedItemsCarousel_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HpSharedExcellentValuesCarousel_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HpSharedBestsellingDesignsCarousel_user"
        }
      ]
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "3c28154dce2a944845b960c0337db756";

export default node;
