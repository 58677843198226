/**
 * @generated SignedSource<<df0a7cb8c05bb66f57ce5700ac3449f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecentlyViewedItemsHeart_items$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"useRecentHistoryList_items">;
  readonly " $fragmentType": "RecentlyViewedItemsHeart_items";
}>;
export type RecentlyViewedItemsHeart_items$key = ReadonlyArray<{
  readonly " $data"?: RecentlyViewedItemsHeart_items$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecentlyViewedItemsHeart_items">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "RecentlyViewedItemsHeart_items",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useRecentHistoryList_items"
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "b36c0935514aefed52a0bc05245b41b4";

export default node;
