/**
 * @generated SignedSource<<8ff016fe15977da73ad8a167578750b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedNewArrivalsModule_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ItemTile_viewer">;
  readonly " $fragmentType": "HpSharedNewArrivalsModule_viewer";
};
export type HpSharedNewArrivalsModule_viewer$key = {
  readonly " $data"?: HpSharedNewArrivalsModule_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedNewArrivalsModule_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedNewArrivalsModule_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ItemTile_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "94598e3230fc97b91ecbbc455c1e5775";

export default node;
