import { lazy, type FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import classNames from 'classnames';

// components
import { FormattedNumber } from 'dibs-react-intl';
import { ClientSuspense } from 'dibs-elements/exports/ClientSuspense';

// helpers
import { useAuctionPricing } from 'dibs-auctions/exports/hooks/useAuctionPricing';

// types
import { type AuctionPricePaddle_item$key } from './__generated__/AuctionPricePaddle_item.graphql';

// styles
import styles from './AuctionPricePaddle.scss';

const PaddleIcon = lazy(
    () => import(/* webpackChunkName: "PaddleIcon" */ 'dibs-icons/exports/legacy/Paddle')
);

const itemFragment = graphql`
    fragment AuctionPricePaddle_item on Item
    @argumentDefinitions(page: { type: PageDisplayEnum, defaultValue: null }) {
        isAuctionEnabled
        ...useAuctionPricing_item @arguments(page: $page)
    }
`;

type Props = {
    item: AuctionPricePaddle_item$key;
    currency: string;
    showPill?: boolean;
};

export const AuctionPricePaddle: FC<Props> = ({ item: itemRef, currency, showPill }) => {
    const item = useFragment(itemFragment, itemRef);
    const { currentBidAmount, startingBidAmount } = useAuctionPricing({ item, currency });

    const { isAuctionEnabled } = item;
    const amount = currentBidAmount || startingBidAmount;

    if (!amount || !isAuctionEnabled) {
        return null;
    }

    return (
        <ClientSuspense fallback={null}>
            <div
                className={classNames(styles.priceWrapper, {
                    [styles.pillWrapper]: showPill,
                })}
            >
                <PaddleIcon className={styles.paddle} />
                <FormattedNumber
                    value={amount}
                    currency={currency}
                    style="currency"
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                />
            </div>
        </ClientSuspense>
    );
};
