/**
 * @generated SignedSource<<1cfe0698d49eddee67c47475eb2403e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type newArrivalsTracking_item$data = {
  readonly browseUrl: string | null;
  readonly categoryCode: string | null;
  readonly ecommerceTrackingParams: any | null;
  readonly serviceId: string | null;
  readonly " $fragmentType": "newArrivalsTracking_item";
};
export type newArrivalsTracking_item$key = {
  readonly " $data"?: newArrivalsTracking_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"newArrivalsTracking_item">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "newArrivalsTracking_item"
};

(node as any).hash = "c8e37bdb98fdb8bf0eb70d50ed318346";

export default node;
