/**
 * @generated SignedSource<<4070eb1d6f1f393c86b7367ce7a80cf2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedMostSavedItemsCarousel_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedItemTilesCarouselModule_user">;
  readonly " $fragmentType": "HpSharedMostSavedItemsCarousel_user";
};
export type HpSharedMostSavedItemsCarousel_user$key = {
  readonly " $data"?: HpSharedMostSavedItemsCarousel_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedMostSavedItemsCarousel_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedMostSavedItemsCarousel_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HpSharedItemTilesCarouselModule_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "4a2aa8c0161953525bdd5d94ee85569b";

export default node;
