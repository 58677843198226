import {
    trackEvent,
    trackEcommerce,
    type EcomTypeValues,
    type EventNameValues,
    trackingConstants,
    eventNameConstants,
    interactionTypeConstants,
    type StepInteractionNameValues,
} from 'dibs-tracking';

type Item = {
    serviceId: string | null;
    contemporaryTrackingString: string;
    title: string | null;
    browseUrl: string | null;
    categoryCode: string | null;
    seller: {
        serviceId: string | null;
    } | null;
} | null;

type Items = readonly Item[];

const trackInteraction = ({ action, label }: { action: string; label: string }): void => {
    trackEvent({
        category: 'promo interaction',
        action,
        label,
        value: 0,
    });
};

const trackItemClick = ({ actionLabel }: { actionLabel: string }): void => {
    trackInteraction({
        action: `${actionLabel} items item clicked`,
        label: 'none',
    });
};

const trackInitialDisplay = ({
    itemsReturned,
    actionLabel,
}: {
    itemsReturned: number;
    actionLabel: string;
}): void => {
    trackInteraction({
        action: `${actionLabel} items displayed`,
        label: itemsReturned.toString(),
    });
};

const trackArrowClick = ({
    direction,
    actionLabel,
}: {
    direction: string;
    actionLabel: string;
}): void => {
    trackInteraction({
        action: `${actionLabel} items arrow clicked`,
        label: direction,
    });
};

const { ECOM_PRODUCT_CLICK, ECOM_PRODUCT_IMPRESSION } = trackingConstants;
const { EVENT_SELECT_ITEM, EVENT_VIEW_ITEM_LIST, EVENT_WISHLIST } = eventNameConstants;
const { INTERACTION_TYPE_ITEM_FAVORITING } = interactionTypeConstants;

const trackCarouselItems = ({
    type,
    items,
    startIndex,
    variant,
    actionLabel,
    eventName,
}: {
    type: EcomTypeValues;
    items: Items;
    startIndex: number;
    variant: string;
    actionLabel: string;
    eventName: EventNameValues;
}): void => {
    const actionField = {
        list: `${actionLabel} carousel`,
        ...(type === ECOM_PRODUCT_CLICK ? { itemId: items?.[0]?.serviceId } : {}),
    };

    trackEcommerce({
        type,
        eventName,
        actionField,
        products: items.map((item: Item, index: number) => ({
            brand: item?.seller?.serviceId,
            category: item?.browseUrl,
            categoryCode: item?.categoryCode,
            id: item?.serviceId,
            variant,
            name: item?.title,
            list: `${actionLabel} carousel`,
            dimension83: item?.contemporaryTrackingString,
            position: startIndex + index,
        })),
    });
};
const trackFavoriting = ({
    action,
    itemId,
    actionLabel,
    step_interaction_name,
}: {
    itemId: string | null;
    action: string;
    actionLabel: string;
    step_interaction_name: StepInteractionNameValues;
}): void => {
    const trigger = `homepage - ${actionLabel}`;
    trackEvent(
        {
            action,
            label: trigger,
            category: INTERACTION_TYPE_ITEM_FAVORITING,
            isInteractiveEvent: true,
            ...(itemId ? { additional: { itemId } } : {}),
            eventName: EVENT_WISHLIST,
            interaction_type: INTERACTION_TYPE_ITEM_FAVORITING,
            step_interaction_name,
            trigger,
        },
        null
    );
};

const handleItemClick = ({
    items,
    index,
    actionLabel,
}: {
    items: Items;
    index: number;
    actionLabel: string;
}): void => {
    trackItemClick({ actionLabel });
    trackCarouselItems({
        actionLabel,
        type: ECOM_PRODUCT_CLICK,
        items: items,
        startIndex: index + 1, // Tracking needs non zero index position.
        variant: 'homepage',
        eventName: EVENT_SELECT_ITEM,
    });
};

const trackImpressions = ({
    visibleItems,
    index,
    actionLabel,
}: {
    visibleItems: Items;
    index: number;
    actionLabel: string;
}): void => {
    trackCarouselItems({
        actionLabel,
        type: ECOM_PRODUCT_IMPRESSION,
        items: visibleItems,
        startIndex: index + 1, // Tracking needs non zero index position.
        variant: 'homepage',
        eventName: EVENT_VIEW_ITEM_LIST,
    });
};

export {
    trackItemClick,
    trackInitialDisplay,
    trackArrowClick,
    trackImpressions,
    handleItemClick,
    trackFavoriting,
    trackCarouselItems,
};
