import { type FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { InViewport } from 'dibs-in-viewport/exports/InViewport';
import { SeoLink } from 'dibs-seo/exports/SeoLink';

import { HpSharedModuleContainer } from '../HpSharedModuleContainer/HpSharedModuleContainer';
import { HpSharedFeaturedModuleItem } from './HpSharedFeaturedModuleItem';
import { trackFeaturedImpressions, trackFeaturedClick } from './helpers/FeaturedTracking';
import { trackModuleLocation, trackModule } from '../helpers/moduleLocationTracking';
import HpLazyImageWrapper from '../HpLazyImageWrapper/HpLazyImageWrapper';
import ResponsiveImage from '../ResponsiveImage';
import styles from './HpSharedFeaturedModule.scss';

import { type HpSharedFeaturedModule_componentModule$data } from './__generated__/HpSharedFeaturedModule_componentModule.graphql';

export type Items = HpSharedFeaturedModule_componentModule$data['items'];
export type PrimaryItem = HpSharedFeaturedModule_componentModule$data['primaryItem'];
export type Item = NonNullable<HpSharedFeaturedModule_componentModule$data['items']>[0];

export type LinkData = NonNullable<
    NonNullable<HpSharedFeaturedModule_componentModule$data['items']>[0]
>['linkData'];

type Args = {
    inViewport: boolean;
    items: Items;
    primaryItem: PrimaryItem;
};

const handleInitialDisplay = ({ inViewport, items, primaryItem }: Args): void => {
    if (primaryItem && inViewport) {
        if (items) {
            trackFeaturedImpressions([primaryItem, ...items]);
        } else {
            trackFeaturedImpressions([primaryItem]);
        }
    }
};

type Props = {
    componentModule: HpSharedFeaturedModule_componentModule$data;
    useLazyLoadImages: boolean;
    moduleIndex: number;
    totalModules: number;
};

const HpSharedFeaturedModuleComponent: FC<Props> = ({
    componentModule,
    useLazyLoadImages,
    moduleIndex,
    totalModules,
}) => {
    if (!componentModule) {
        return null;
    }

    const { items, primaryItem, cmsDisplayTitle } = componentModule;
    const {
        mobileImageHeight,
        mobileImageWidth,
        mobileImageUrl,
        imageHeight,
        imageWidth,
        imageUrl,
        backgroundColor,
    } = primaryItem || {};
    const primaryImageHeight = mobileImageHeight || imageHeight;
    const primaryImageWidth = mobileImageWidth || imageWidth;
    const primaryImageUrl = mobileImageUrl || imageUrl;

    const handleClick = (item: Item | PrimaryItem, index: number): void => {
        trackFeaturedClick(item, index);
        trackModuleLocation({
            label: 'five block module',
            moduleIndex,
            totalModules,
        });
        trackModule(cmsDisplayTitle);
    };

    return (
        <HpSharedModuleContainer addTopGap>
            <InViewport
                stopWhenInViewport
                onInViewportChange={({ inViewport }) =>
                    handleInitialDisplay({ inViewport, items, primaryItem })
                }
            >
                <div className="rowFlex">
                    <div className={`${styles.primaryItem} colXs12 colLg6`}>
                        <SeoLink
                            className={styles.primaryLink}
                            style={{ ...(backgroundColor && { backgroundColor }) }}
                            linkData={primaryItem?.linkData as LinkData}
                            ariaLabel={primaryItem?.cta || ''}
                            onClick={() => handleClick(primaryItem, 0)}
                        >
                            <HpLazyImageWrapper
                                useLazyLoad={useLazyLoadImages}
                                imageHeight={primaryImageHeight}
                                imageWidth={primaryImageWidth}
                            >
                                <ResponsiveImage
                                    className={styles.primaryImage}
                                    src={primaryImageUrl || ''}
                                    altText={primaryItem?.title || ''}
                                    sizes="(max-width:768px) 95vw, 50vw"
                                    srcSetWidths={[400, 640, 800, 960, 1200]}
                                />
                            </HpLazyImageWrapper>
                        </SeoLink>
                    </div>

                    <div className={`${styles.productItems} colXs12 colLg6`}>
                        <div className={`${styles.fullHeight} rowFlex`}>
                            {items?.map((item, index) => (
                                <HpSharedFeaturedModuleItem
                                    key={`${item?.linkData?.path}-${index}`}
                                    item={item}
                                    useLazyLoadImages={useLazyLoadImages}
                                    onClick={() => {
                                        /**
                                         * Index incremented by 1 because primaryItem is using `position: 1`
                                         */
                                        handleClick(item, index + 1);
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </InViewport>
        </HpSharedModuleContainer>
    );
};

export const HpSharedFeaturedModule = createFragmentContainer(HpSharedFeaturedModuleComponent, {
    componentModule: graphql`
        fragment HpSharedFeaturedModule_componentModule on FeaturedModule {
            cmsDisplayTitle
            primaryItem {
                cta
                imageUrl
                imageHeight
                imageWidth
                ... @include(if: $isMobile) {
                    mobileImageHeight
                    mobileImageWidth
                    mobileImageUrl
                }
                title
                linkData {
                    # FeaturedTracking.js
                    path
                    ...SeoLink_linkData
                }
                backgroundColor
            }
            items {
                cmsId
                cta
                imageUrl
                title
                linkData {
                    # FeaturedTracking.js
                    path
                    ...SeoLink_linkData
                }
                backgroundColor
                textColor
            }
        }
    `,
});
