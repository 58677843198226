import { useState, useEffect, type FC } from 'react';
import classnames from 'classnames';

import { VideoTile } from 'dibs-elements/exports/VideoTile';

import dibsCss from 'dibs-css';
import styles from './HpSharedVideoTile.scss';

type Props = {
    isMobile?: boolean;
    posterOverride?: string;
    videoPlaying: boolean;
    autoplay?: boolean;
    videoUrl?: string;
    onVideoStateChange: () => void;
};

export const HpSharedVideoTile: FC<Props> = ({
    isMobile,
    posterOverride,
    onVideoStateChange = () => {},
    videoPlaying,
    videoUrl,
    autoplay,
}) => {
    const [currentVideoPlaying, setCurrentVideoPlaying] = useState(false);

    useEffect(() => {
        if (currentVideoPlaying && !videoPlaying) {
            setCurrentVideoPlaying(false);
        }
    }, [currentVideoPlaying, videoPlaying, setCurrentVideoPlaying]);

    let autoplayProps = {};

    if (autoplay) {
        autoplayProps = {
            autoPlay: true,
            loop: true,
            muted: true,
            controls: false,
            animate: true,
        };
    } else {
        autoplayProps = { loop: false, muter: false, controls: true };
    }

    if (videoUrl) {
        return (
            <div
                className={classnames(
                    dibsCss.wFull,
                    dibsCss.hFull,
                    dibsCss.flex,
                    dibsCss.justifyCenter,
                    styles.gridImage
                )}
                data-tn="homepage-video"
            >
                <div
                    className={classnames(
                        dibsCss.relative,
                        dibsCss.wFull,
                        dibsCss.hFull,
                        dibsCss.flex,
                        { [dibsCss.mxMedium]: !isMobile }
                    )}
                >
                    <VideoTile
                        animate={currentVideoPlaying}
                        sourceUrl={videoUrl}
                        posterOverride={posterOverride}
                        objectFit="contain"
                        onPlay={() => {
                            setCurrentVideoPlaying(true);
                            onVideoStateChange();
                        }}
                        onPause={() => {
                            setCurrentVideoPlaying(false);
                            onVideoStateChange();
                        }}
                        noBackground
                        noSpinner
                        {...autoplayProps}
                    />
                </div>
            </div>
        );
    } else {
        return null;
    }
};
