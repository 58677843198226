import { type FetchedItemType, type RecentHistoryItemType } from './recentHistoryTypes';

export const getFormattedItemsReducer = (
    acc: RecentHistoryItemType[],
    item: FetchedItemType | null
): RecentHistoryItemType[] => {
    if (!item || !item.title || !item.pdpURL || !item.firstPhotoWebPath || !item.serviceId) {
        return acc;
    }

    const {
        categoryPath: category,
        contemporaryTrackingString,
        seller,
        browseUrl: itemBrowseUrl,
        categoryCode: itemCategoryCode,
        serviceId: itemId,
        firstPhotoWebPath: itemImageUrl,
        pdpURL: itemPdpUrl,
        title: itemTitle,
        ...fragmentSpreads
    } = item;

    return acc.concat({
        category,
        contemporaryTrackingString,
        dealerPk: seller?.serviceId,
        itemBrowseUrl,
        itemCategoryCode,
        itemId,
        itemImageUrl,
        itemPdpUrl,
        itemTitle,
        ...fragmentSpreads,
    });
};
