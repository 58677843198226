/**
 * @generated SignedSource<<a570d17ebc5011995e9705884413199a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedNewArrivalsModule_personalizationModule$data = {
  readonly categoryItems: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ItemTile_item" | "SharedHeart_item" | "newArrivalsTracking_item">;
  } | null> | null;
  readonly categoryTitle: string | null;
  readonly linkData: {
    readonly path: string | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"newArrivalsTracking_personalizationModule">;
  readonly " $fragmentType": "HpSharedNewArrivalsModule_personalizationModule";
};
export type HpSharedNewArrivalsModule_personalizationModule$key = {
  readonly " $data"?: HpSharedNewArrivalsModule_personalizationModule$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedNewArrivalsModule_personalizationModule">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "categoryTitle",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isTrade"
    },
    {
      "kind": "RootArgument",
      "name": "priceBookName"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedNewArrivalsModule_personalizationModule",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LinkData",
      "kind": "LinkedField",
      "name": "linkData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Item",
      "kind": "LinkedField",
      "name": "categoryItems",
      "plural": true,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "newArrivalsTracking_item",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "browseUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "categoryCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ecommerceTrackingParams",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "serviceId",
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SharedHeart_item"
        },
        {
          "args": [
            {
              "kind": "Literal",
              "name": "fetchLiveShipmentQuote",
              "value": false
            },
            {
              "kind": "Variable",
              "name": "isTrade",
              "variableName": "isTrade"
            },
            {
              "kind": "Variable",
              "name": "priceBookName",
              "variableName": "priceBookName"
            }
          ],
          "kind": "FragmentSpread",
          "name": "ItemTile_item"
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "newArrivalsTracking_personalizationModule",
      "selections": [
        (v0/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "PersonalizationModule",
  "abstractKey": null
};
})();

(node as any).hash = "94dc4e0c3fd2aa8e7ce2436197a68c16";

export default node;
