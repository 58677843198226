/**
 * @generated SignedSource<<048e6e66b402955677aae82519b12943>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useRecentHistoryList_item$data = {
  readonly browseUrl: string | null;
  readonly categoryCode: string | null;
  readonly categoryPath: string | null;
  readonly contemporaryTrackingString: string;
  readonly ecommerceTrackingParams: any | null;
  readonly firstPhotoWebPath: string | null;
  readonly pdpURL: string | null;
  readonly seller: {
    readonly serviceId: string | null;
  } | null;
  readonly serviceId: string | null;
  readonly title: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"AuctionPricePaddle_item">;
  readonly " $fragmentType": "useRecentHistoryList_item";
};
export type useRecentHistoryList_item$key = {
  readonly " $data"?: useRecentHistoryList_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"useRecentHistoryList_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useRecentHistoryList_item",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AuctionPricePaddle_item"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ecommerceTrackingParams",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contemporaryTrackingString",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "size",
          "value": "small"
        }
      ],
      "kind": "ScalarField",
      "name": "firstPhotoWebPath",
      "storageKey": "firstPhotoWebPath(size:\"small\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pdpURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "browseUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "categoryCode",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "categoryPath",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Seller",
      "kind": "LinkedField",
      "name": "seller",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};
})();

(node as any).hash = "f50d20eb8b6a772a809bfccfa691f67e";

export default node;
