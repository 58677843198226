/**
 * @generated SignedSource<<4243a36bade221be778c7a0d02921247>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedPersonalizedCategories_componentModule$data = {
  readonly title: string | null;
  readonly " $fragmentType": "HpSharedPersonalizedCategories_componentModule";
};
export type HpSharedPersonalizedCategories_componentModule$key = {
  readonly " $data"?: HpSharedPersonalizedCategories_componentModule$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedPersonalizedCategories_componentModule">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedPersonalizedCategories_componentModule",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "ArrivalsModule",
  "abstractKey": null
};

(node as any).hash = "c02e2463276d8ea9aca7772c6b76b600";

export default node;
