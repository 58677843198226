/**
 * @generated SignedSource<<b3aae24f63a7cfa2d361bb024d38f8d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedCollectionsModule_componentModule$data = {
  readonly cmsDisplayTitle: string | null;
  readonly items: ReadonlyArray<{
    readonly cmsId: number | null;
    readonly title: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"HpSharedCollectionsModuleItem_item">;
  } | null> | null;
  readonly title: string | null;
  readonly " $fragmentType": "HpSharedCollectionsModule_componentModule";
};
export type HpSharedCollectionsModule_componentModule$key = {
  readonly " $data"?: HpSharedCollectionsModule_componentModule$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedCollectionsModule_componentModule">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedCollectionsModule_componentModule",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cmsDisplayTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "items",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cmsId",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HpSharedCollectionsModuleItem_item"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CollectionsModule",
  "abstractKey": null
};
})();

(node as any).hash = "317e4076ed9703a7ea02bc525fd01781";

export default node;
