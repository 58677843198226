import { type FC } from 'react';
import { graphql, useFragment } from 'react-relay';
import HeartWrapper from 'dibs-portfolio-heart';
import {
    trackEvent,
    eventNameConstants,
    interactionTypeConstants,
    stepInteractionConstants,
} from 'dibs-tracking';
import authModalLoader from 'dibs-buyer-layout/src/utils/AuthModalLoader';

import { type SharedHeart_item$key } from './__generated__/SharedHeart_item.graphql';

const { EVENT_WISHLIST } = eventNameConstants;
const { INTERACTION_TYPE_ITEM_FAVORITING } = interactionTypeConstants;
const { STEP_INTERACTION_ITEM_ADDED, STEP_INTERACTION_ITEM_REMOVED } = stepInteractionConstants;

const itemFragment = graphql`
    fragment SharedHeart_item on Item {
        serviceId
    }
`;

type Favorites = $TSFixMe;

export const SharedHeart: FC<{
    item?: SharedHeart_item$key | null;
    trackingList?: string;
    favorites: Favorites;
}> = ({ item: itemRef, trackingList, favorites }) => {
    const item = useFragment(itemFragment, itemRef);

    const { serviceId: itemId } = item || {};

    const authModalShow = (favoriteItem: Favorites): void => {
        if (!favorites.userId) {
            authModalLoader
                .show({
                    flow: authModalLoader.constants.SAVE_ITEM_FLOW,
                    ga: {
                        label: 'save item - "editorial picks" homepage module',
                    },
                })
                .then(favoriteItem);
        }
    };

    return (
        <HeartWrapper
            itemId={itemId}
            loadPortfolioData={favorites.loadPortfolioData}
            viewer={favorites.viewer || null}
            userId={favorites.userId || null}
            userIds={favorites.userIds || []}
            authModalShow={authModalShow}
            onFavorited={() => {
                trackEvent(
                    {
                        action: STEP_INTERACTION_ITEM_ADDED,
                        label: trackingList,
                        category: INTERACTION_TYPE_ITEM_FAVORITING,
                        isInteractiveEvent: true,
                        additional: { itemId },
                        eventName: EVENT_WISHLIST,
                        interaction_type: INTERACTION_TYPE_ITEM_FAVORITING,
                        step_interaction_name: STEP_INTERACTION_ITEM_ADDED,
                        trigger: trackingList,
                    },
                    null
                );
            }}
            onUnFavorited={() => {
                trackEvent(
                    {
                        action: STEP_INTERACTION_ITEM_REMOVED,
                        label: trackingList,
                        category: INTERACTION_TYPE_ITEM_FAVORITING,
                        isInteractiveEvent: true,
                        additional: { itemId },
                        eventName: EVENT_WISHLIST,
                        interaction_type: INTERACTION_TYPE_ITEM_FAVORITING,
                        step_interaction_name: STEP_INTERACTION_ITEM_REMOVED,
                        trigger: trackingList,
                    },
                    null
                );
            }}
            selectedItemIds={favorites.selectedItemIds}
            theme="dark"
        />
    );
};
