/**
 * @generated SignedSource<<c4f2d4b17ba70847d003be7bfc47263a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecentlyViewedItemsCarousel_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"RecentlyViewedItemsHeart_viewer">;
  readonly " $fragmentType": "RecentlyViewedItemsCarousel_viewer";
};
export type RecentlyViewedItemsCarousel_viewer$key = {
  readonly " $data"?: RecentlyViewedItemsCarousel_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecentlyViewedItemsCarousel_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "loadPortfolioData"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "selectedItemIds"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "userIds"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": require('./RecentlyViewedItemsCarouselViewerRefetchQuery.graphql')
    }
  },
  "name": "RecentlyViewedItemsCarousel_viewer",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "loadPortfolioData",
          "variableName": "loadPortfolioData"
        },
        {
          "kind": "Variable",
          "name": "selectedItemIds",
          "variableName": "selectedItemIds"
        },
        {
          "kind": "Variable",
          "name": "userIds",
          "variableName": "userIds"
        }
      ],
      "kind": "FragmentSpread",
      "name": "RecentlyViewedItemsHeart_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "e575f2f6f4fc301f2c50dde7f6a1c733";

export default node;
