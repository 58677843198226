import { type FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { GridCol } from 'dibs-elements/exports/Grid';
import slugify from 'dibs-slugify/exports/slugify';
import HpSharedModuleItemTile from '../HpSharedModuleItemTile/HpSharedModuleItemTile';

import styles from './HpSharedShopByModuleItem.scss';

import { type HpSharedShopByModuleItem_item$data } from './__generated__/HpSharedShopByModuleItem_item.graphql';

type Props = {
    item: HpSharedShopByModuleItem_item$data;
    onClick: () => void;
    useLazyLoadImages: boolean;
};

export const HpSharedShopByModuleItem: FC<Props> = ({
    item,
    onClick = () => {},
    useLazyLoadImages,
}) => {
    const { linkData, title, description, imageUrl, imageHeight, imageWidth, cta } = item;
    return (
        <GridCol mobile={6} tabletLandscape={3}>
            <div className={styles.item}>
                <HpSharedModuleItemTile
                    linkData={linkData}
                    dataTn={`shopBy-module-item-${slugify(item.title)}`}
                    onClick={onClick}
                    title={title}
                    description={description}
                    cta={cta}
                    imageUrl={imageUrl}
                    imgSrcSetWidths={[230, 360, 500, 760]}
                    imgSizes="(max-width:768px) 50vw, (max-width:1024px) 230px, 360px"
                    imageHeight={imageHeight}
                    imageWidth={imageWidth}
                    useLazyLoadImages={useLazyLoadImages}
                />
            </div>
        </GridCol>
    );
};

export default createFragmentContainer(HpSharedShopByModuleItem, {
    item: graphql`
        fragment HpSharedShopByModuleItem_item on Product {
            title
            description
            cta
            imageUrl
            imageHeight
            imageWidth
            linkData {
                ...HpSharedModuleItemTile_linkData
            }
        }
    `,
});
