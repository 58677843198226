import { type FC, type ReactNode } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { graphql, useFragment, type Environment } from 'react-relay';
import { Mosaic } from 'dibs-mosaic/exports/Mosaic';
import { HeartWrapper } from 'dibs-portfolio-heart';
import { Carousel } from 'dibs-carousel';
import SharedFavoritesProvider from '../../sharedComponents/SharedFavoritesProvider';
import { HpSharedCarouselTracking } from '../HpSharedCarouselTracking/HpSharedCarouselTracking';
import { trackCarouselItems, trackFavoriting, trackArrowClick } from '../helpers/carouselTracking';
import { trackModule, trackModuleLocation } from '../helpers/moduleLocationTracking';
import classNames from 'classnames';
import styles from './HpSharedRecentActivityModule.scss';
import { Link } from 'dibs-elements/exports/Link';
import { SharedCarouselDot } from '../../sharedComponents/SharedCarouselDot/SharedCarouselDot';
import authModalLoader from 'dibs-buyer-layout/src/utils/AuthModalLoader';
import { getSrcsetString, getSrcsetUrl } from 'dibs-image-utils/exports/srcSet';
import {
    trackingConstants,
    eventNameConstants,
    stepInteractionConstants,
    trackUserEventItemAddToFavorites,
    getUserEventItem,
} from 'dibs-tracking';

import { type HpSharedRecentActivityModule_viewer$key } from './__generated__/HpSharedRecentActivityModule_viewer.graphql';
import { type HpSharedRecentActivityModule_componentModule$key } from './__generated__/HpSharedRecentActivityModule_componentModule.graphql';

const RECENT_ACTIVITY_LAYOUTS = [
    // mobile variants
    {
        isMobile: true,
        itemsPerPage: 6,
        layout: `
            i0 i2 i4
            i1 i3 i5
        `,
    },
    {
        isMobile: true,
        itemsPerPage: 8,
        layout: `
            i0 i2 i4 i6
            i1 i3 i5 i7
        `,
    },
    {
        isMobile: true,
        itemsPerPage: 10,
        layout: `
            i0 i2 i4 i6 i8
            i1 i3 i5 i7 i9
        `,
    },
    {
        isMobile: true,
        itemsPerPage: 12,
        layout: `
            i0 i2 i4 i6 i8 i10
            i1 i3 i5 i7 i9 i11
        `,
    },

    // responsive variants
    {
        isMobile: false,
        itemsPerPage: 6,
        layout: `
            i0 i0 i1 i3 i3 i4
            i0 i0 i2 i3 i3 i5
        `,
    },
    {
        isMobile: false,
        itemsPerPage: 9,
        layout: `
            i0 i0 i3 i4 i6 i6
            i0 i0 i5 i5 i6 i6
            i1 i2 i5 i5 i7 i8 
        `,
    },
];

type Props = {
    userId: string;
    environment: Environment;
    viewer: HpSharedRecentActivityModule_viewer$key;
    fetchFavorites: boolean;
    isMobile: boolean;
    componentModule: HpSharedRecentActivityModule_componentModule$key;
    moduleIndex: number;
    totalModules: number;
};

const ACTION_LABEL = 'homepage recent activity';

export type SharedFavoritesProps = {
    favorites: {
        loadPortfolioData: boolean;
        viewer: HpSharedRecentActivityModule_viewer$key;
        userId: string;
        userIds: string[];
        selectedItemIds: string[];
    };
};

const viewerFragment = graphql`
    fragment HpSharedRecentActivityModule_viewer on Viewer {
        ...SharedFavoritesProvider_viewer
    }
`;

const componentModuleFragment = graphql`
    fragment HpSharedRecentActivityModule_componentModule on RecentActivityModule {
        cmsDisplayTitle
        recentActivityItemsWithMeta(
            userId: $userId
            localRecentHistoryList: $localRecentHistoryList
            recentActivityDesignVariant: $recentActivityDesignVariant
        ) {
            itemsPerPage
            items {
                type
                serviceItem {
                    serviceId
                    ecommerceTrackingParams
                    contemporaryTrackingString
                    title
                    browseUrl
                    categoryCode
                    seller {
                        serviceId
                    }
                    photos(limit: 1) {
                        smallPath
                    }
                    localizedPdpUrl
                }
            }
        }
    }
`;

export const HpSharedRecentActivityModule: FC<Props> = ({
    userId,
    environment,
    viewer: viewerRef,
    fetchFavorites,
    isMobile,
    componentModule: componentModuleRef,
    moduleIndex,
    totalModules,
}) => {
    const componentModule = useFragment(componentModuleFragment, componentModuleRef);
    const viewer = useFragment(viewerFragment, viewerRef);
    const { recentActivityItemsWithMeta, cmsDisplayTitle } = componentModule;
    const { ECOM_PRODUCT_CLICK, ECOM_PRODUCT_IMPRESSION } = trackingConstants;
    const { EVENT_SELECT_ITEM, EVENT_VIEW_ITEM_LIST } = eventNameConstants;
    const { STEP_INTERACTION_ITEM_ADDED, STEP_INTERACTION_ITEM_REMOVED } = stepInteractionConstants;

    // prepare items
    const itemsPerPage = recentActivityItemsWithMeta?.itemsPerPage || 0;
    const items = recentActivityItemsWithMeta?.items;
    if (!items?.length) {
        return null;
    }

    // prepare layout
    const layout = RECENT_ACTIVITY_LAYOUTS.find(
        item => item.isMobile === isMobile && item.itemsPerPage === itemsPerPage
    );
    if (!layout) {
        return null; // suitable layout was not found
    }

    // prepare pages
    const pages = Math.ceil(items.length / itemsPerPage);
    const showDotsAndArrows = pages && pages > 1;

    // prepare favorites
    const favoritesProviderProps = {
        userId,
        environment,
        viewer,
        itemIds: items.map(item => item?.serviceItem?.serviceId),
        fetchFavorites: fetchFavorites && items.length > 0,
    };
    const authModalShow = (favoriteItem: SharedFavoritesProps['favorites']): void => {
        if (!userId) {
            authModalLoader
                .show({
                    flow: authModalLoader.constants.SAVE_ITEM_FLOW,
                })
                .then(favoriteItem);
        }
    };

    const renderMosaic = (
        index: number,
        favorites: SharedFavoritesProps['favorites']
    ): JSX.Element => (
        <Mosaic
            layout={layout.layout}
            renderTile={key => {
                // get index from key
                const itemIndex = index * itemsPerPage + Number(key.replace(/\D/g, '')); // convert i1 to 1
                const item = items[itemIndex];
                const { type, serviceItem } = item || {};
                if (!serviceItem) {
                    return null;
                }
                const { photos, ecommerceTrackingParams, serviceId, localizedPdpUrl, title } =
                    serviceItem;
                const smallPath = photos?.[0]?.smallPath;

                return (
                    <div className={styles.item}>
                        <div className={styles.innerItem}>
                            <HeartWrapper
                                className={styles.heartWrapper}
                                itemId={serviceId}
                                loadPortfolioData={favorites?.loadPortfolioData}
                                viewer={favorites?.viewer || null}
                                userId={favorites?.userId || null}
                                userIds={favorites?.userIds || []}
                                authModalShow={authModalShow}
                                selectedItemIds={favorites?.selectedItemIds}
                                theme={'dark'}
                                onFavorited={() => {
                                    trackFavoriting({
                                        action: 'item added',
                                        itemId: serviceId || '',
                                        actionLabel: 'recent activity',
                                        step_interaction_name: STEP_INTERACTION_ITEM_ADDED,
                                    });
                                    const userEventItem = getUserEventItem({
                                        id: serviceId,
                                        amount: ecommerceTrackingParams?.price,
                                    });
                                    if (userEventItem) {
                                        trackUserEventItemAddToFavorites(environment, [
                                            userEventItem,
                                        ]);
                                    }
                                }}
                                onUnFavorited={() =>
                                    trackFavoriting({
                                        action: 'item removed',
                                        itemId: serviceId || '',
                                        actionLabel: 'recent activity',
                                        step_interaction_name: STEP_INTERACTION_ITEM_REMOVED,
                                    })
                                }
                            />

                            <Link
                                href={localizedPdpUrl || ''}
                                onClick={() => {
                                    trackCarouselItems({
                                        actionLabel: ACTION_LABEL,
                                        type: ECOM_PRODUCT_CLICK,
                                        items: [serviceItem],
                                        startIndex: itemIndex + 1, // non-zero tracking index
                                        variant:
                                            type === 'recentlySaved'
                                                ? 'recently saved'
                                                : 'recently viewed',
                                        eventName: EVENT_SELECT_ITEM,
                                    });
                                    trackModuleLocation({
                                        label: 'recent activity',
                                        moduleIndex,
                                        totalModules,
                                    });
                                    trackModule('recent activity');
                                }}
                            >
                                <img
                                    className={styles.itemImage}
                                    alt={title || ''}
                                    srcSet={getSrcsetString(smallPath || '')}
                                    src={getSrcsetUrl(smallPath || '', 240)}
                                    sizes={'30vw'}
                                />
                            </Link>
                        </div>
                    </div>
                );
            }}
            gap={18}
            autoAspectRatio
        />
    );

    return (
        <SharedFavoritesProvider {...favoritesProviderProps}>
            {({ favorites }: SharedFavoritesProps) => {
                return (
                    <HpSharedCarouselTracking
                        items={items}
                        itemsPerPage={itemsPerPage}
                        onItemsImpression={({ visibleItems, index }) => {
                            let currentIndex = index + 1; // non-zero tracking index
                            visibleItems.forEach(visibleItem => {
                                trackCarouselItems({
                                    actionLabel: ACTION_LABEL,
                                    type: ECOM_PRODUCT_IMPRESSION,
                                    items: [visibleItem?.serviceItem || null],
                                    startIndex: currentIndex++,
                                    variant:
                                        visibleItem?.type === 'recentlySaved'
                                            ? 'recently saved'
                                            : 'recently viewed',
                                    eventName: EVENT_VIEW_ITEM_LIST,
                                });
                            });
                        }}
                    >
                        {({ handleIndexChange }) => (
                            <div
                                className={classNames(styles.container, {
                                    [styles.isMobile]: isMobile,
                                })}
                            >
                                <div className={styles.titleWrapper}>
                                    <h2
                                        className={styles.title}
                                        dangerouslySetInnerHTML={{ __html: cmsDisplayTitle || '' }}
                                    />

                                    {userId && (
                                        <FormattedMessage
                                            id="HpSharedRecentActivityModule.subtitle"
                                            defaultMessage="<a_1>Recently Viewed</a_1> and <a_2>Recently Saved</a_2>"
                                            values={{
                                                a_1: (chunks: ReactNode[]) => {
                                                    return (
                                                        <Link href="/favorites/viewed/">
                                                            {chunks}
                                                        </Link>
                                                    );
                                                },
                                                a_2: (chunks: ReactNode[]) => {
                                                    return (
                                                        <Link href="/favorites/items/">
                                                            {chunks}
                                                        </Link>
                                                    );
                                                },
                                            }}
                                        />
                                    )}
                                </div>

                                <div
                                    className={classNames(styles.carouselWrapper, {
                                        [styles.showDotsAndArrows]: showDotsAndArrows,
                                    })}
                                >
                                    <div
                                        style={{
                                            // make carousel scrollable on mobile
                                            width: isMobile
                                                ? `${(itemsPerPage / 2) * 45}%`
                                                : '100%',
                                        }}
                                    >
                                        <Carousel
                                            classNames={{
                                                wrapper: styles.wrapper,
                                                dotsWrapper: styles.dotsWrapper,
                                                arrow: styles.arrow,
                                                arrowLeft: styles.arrowLeft,
                                                arrowRight: styles.arrowRight,
                                            }}
                                            totalItems={pages}
                                            onIndexChange={handleIndexChange}
                                            showDots={showDotsAndArrows}
                                            hideArrows={!showDotsAndArrows}
                                            onLeftArrowClick={() =>
                                                trackArrowClick({
                                                    direction: 'left',
                                                    actionLabel: ACTION_LABEL,
                                                })
                                            }
                                            onRightArrowClick={() =>
                                                trackArrowClick({
                                                    direction: 'right',
                                                    actionLabel: ACTION_LABEL,
                                                })
                                            }
                                            renderItem={({ index }: { index: number }) =>
                                                renderMosaic(index, favorites)
                                            }
                                            renderDot={({
                                                isCurrentDot,
                                            }: { isCurrentDot?: boolean } = {}) => (
                                                <SharedCarouselDot isActive={isCurrentDot} />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </HpSharedCarouselTracking>
                );
            }}
        </SharedFavoritesProvider>
    );
};
